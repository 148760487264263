<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import AddressForm from '@/customer/Pages/Profile/Partials/AddressForm.vue';
import BillingAddressForm from '@/customer/Pages/Profile/Partials/BillingAddressForm.vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

const props = defineProps({
    mustVerifyEmail: {
        type: Boolean,
    },
    status: {
        type: String,
    },
    type: {
        type: String,
    }
});

const form = useForm(route().current(), {
    name: null,
    phone: '',
    street: null,
    city: null,
    state: null,
    zip: null,
    landmarks: null,
    building: null,
    suite: null,
    floor: null,
    drop_off: false,
    notes: null,
    call: false,
    type: props.type ?? 'delivery',
});

const submit = () => {
    const url = route('customer.profile.addresses.store');

    form.post(url, {
        preserveScroll: true,
        preserveState: true,
    });
};
</script>

<template>
    <AccountSettingsLayout :title="type == 'billing' ? lang.title_add_billing : lang.title_add_delivery" class="pb-6 md:pb-0">
        <Head title="Profile" />

        <AddressForm v-if="type != 'billing'" v-bind="{ form }" @submit="submit" />
        <BillingAddressForm v-else v-bind="{form}" @submit="submit" />
    </AccountSettingsLayout>
</template>

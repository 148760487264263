<script setup>
import { ref, onMounted } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ReadyAtFields from './ReadyAtFields.vue';
import FulfillmentSelector from './FulfillmentSelector.vue';

const props = defineProps({
    step: {
        type: String,
        required: true,
    },
    inComponent: {
        type: String,
        default: 'Order Summary',
    },
});

const opacity = ref({
    container: 'opacity-0',
    content: 'opacity-0',
});

const inner = ref();

onMounted(() => {
    inner.value = window.innerWidth > 1000;
    setTimeout(() => {
        opacity.value.container =
            props.inComponent === 'OrderSummary' && inner.value ? 'opacity-40' : 'opacity-80';
        opacity.value.content = 'opacity-100';
    }, 100);
});
</script>

<template>
    <div
        class="left-0 top-0 z-[550] h-full w-full"
        :class="[inComponent === 'OrderSummary' && inner ? 'absolute' : 'fixed']"
    >
        <div
            class="transitionRules absolute left-0 top-0 h-full w-full bg-palmers-charcoal-grey"
            :class="[
                opacity.container,
                inComponent === 'OrderSummary' && inner ? 'rounded-lg' : '',
            ]"
        />
        <div
            class="transitionRules absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-3 p-2 md:p-6"
            :class="opacity.content"
            @click="$emit('close-modal')"
        >
            <div
                class="relative w-full rounded-lg bg-white py-6 shadow-lg md:px-8"
                :class="[
                    inComponent === 'OrderSummary' && inner
                        ? 'max-w-xl'
                        : 'max-w-3xl ring-[2px] ring-palmers-charcoal-grey',
                ]"
                @click.stop
            >
                <button
                    @click="$emit('close-modal')"
                    class="absolute right-6 top-4 cursor-pointer supports-hover:hover:opacity-60"
                >
                    <XMarkIcon class="size-6 text-palmers-charcoal-grey" />
                </button>
                <FulfillmentSelector v-if="step === 'fulfillment_type'" />
                <ReadyAtFields />
                <!-- <div class="mt-3 flex w-full justify-center gap-4 px-4 pb-3">
                    <button
                        class="flex gap-2 rounded-lg bg-palmers-dragon-fruit px-6 py-3 font-semibold text-white"
                    >
                        Confirm
                        <HandThumbUpIcon class="size-6 text-white" />
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<style scope>
.transitionRules {
    @apply transition-opacity duration-[250ms] ease-in-out;
}
</style>

<script setup>
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import { SelectionSummary, TrailingInputs, AccordionPanel } from '.';
import { Image } from '@/customer/Components';
import { useMenuItemStore } from '@/customer/Composables';
import StartOrderButton from './StartOrderButton.vue';
import SizeSelectorPill from './SizeSelectorPill.vue';
import { formatCurrency } from '@/customer/Utils';
import { PrintButton } from '@/common/Components';

const $store = useMenuItemStore();

const props = defineProps({
    parentComboItemOption: { type: Number },
    parentComboItem: { type: Number },
    choiceId: { type: Number },
    adderPricingSize: { type: Number },
    menuItem: { type: Object },
    price: { type: [String, Array] },
    name: { type: String, default: null },
    instructions: { type: String, default: null },
    innerConfigSelections: { type: Object, default: () => ({}) },
    innerConfigQuantities: { type: Object, default: () => ({}) },
    hideUpgrades: { type: Boolean, default: true },
});

const showDisplaySummary = ref(props.menuItem.displaySummary ?? false);

// initialize refs related to resizing addToCart button
const buttonPositionAttribute = ref('fixed');
const addToCartRef = ref(null);
const leftSideRef = ref(null);

// callback to position addToCart button depending on position (element's width as fixed isn't bound to parent like absolute)
let wasFixed = false;
const setAddToButtonCartWidth = () => {
    if (leftSideRef.value && addToCartRef.value) {
        const rect = leftSideRef.value.getBoundingClientRect();
        if (buttonPositionAttribute.value === 'fixed') {
            addToCartRef.value.style.left = rect.left + 'px';
            addToCartRef.value.style.width = rect.width + 'px';
            wasFixed = true;
        } else if (wasFixed) {
            wasFixed = false;
            addToCartRef.value.style.left = 0;
            addToCartRef.value.style.width = rect.width + 'px';
        }
    }
};

const rightContainerRef = ref(null);
const checkHeight = () => {
    if (
        rightContainerRef.value &&
        rightContainerRef.value.getBoundingClientRect().bottom + 16 >= window.innerHeight
    ) {
        buttonPositionAttribute.value = 'fixed';
    } else {
        buttonPositionAttribute.value = 'absolute';
    }
    setAddToButtonCartWidth();
};

/**
 * since the addToCart button isn't necessarily mounted on mount
 * because it waits for $store.ready to resolve to true
 * watch the ref for when the element mounts and call width function
 */
watch(
    () => addToCartRef.value,
    (newVal) => {
        if (newVal) {
            setAddToButtonCartWidth();
        }
    },
    { immediate: true, deep: true },
);

/**
 * on mount, get DOM Rect for page header, initiate flow with checkHeight
 * add eventListeners to scroll and resize to maintain UI
 */
onMounted(() => {
    checkHeight();
    window.addEventListener('scroll', checkHeight);
    window.addEventListener('resize', setAddToButtonCartWidth);
});

// clean up eventListeners
onUnmounted(() => {
    window.removeEventListener('scroll', checkHeight);
    window.removeEventListener('resize', setAddToButtonCartWidth);
});

const accordionAction = () => {
    setTimeout(() => {
        checkHeight();
    }, 100);
};
const choicePrice = computed(() => $store.totalChoicePrice + $store.totalInnerChoicePrice);
const totalPriceWithoutChoice = computed(() => $store.price.raw);
</script>

<template>
    <div class="relative grid grid-cols-1">
        <!-- right half -->
        <div class="flex flex-col" ref="rightContainerRef">
            <div class="space-y-4">
                <!-- name -->
                <h4 class="text-l font-semibold uppercase text-palmers-charcoal-grey">
                    {{ menuItem.name }}
                </h4>

                <div class="flex items-center justify-between" v-if="menuItem.type === 'schedule'">
                    <a
                        :href="menuItem.scheduleHref"
                        target="_blank"
                        class="font-bold text-palmers-dragon-fruit supports-hover:hover:underline"
                    >
                        View Schedule
                    </a>

                    <PrintButton
                        theme="pink"
                        title="Print soup schedule"
                        :url="menuItem.scheduleHref"
                    />
                </div>

                <!-- description -->
                <p class="prose">
                    {{ menuItem.description }}
                </p>
            </div>

            <div class="relative z-10 mt-[20px]">
                <!-- boxes -->
                <AccordionPanel
                    @update:selections="$emit('update:selections', $event)"
                    @update:quantities="$emit('update:quantities', $event)"
                    @action="accordionAction"
                    :menuItem="menuItem"
                    :item-configurations="menuItem.configurations"
                    :item-combo-item-options="menuItem.comboItemOptions"
                    :hide-upgrades="hideUpgrades"
                    :quantities="innerConfigQuantities"
                    :selections="innerConfigSelections"
                    :ready="$store.ready"
                    as="list"
                    v-if="$store.ready"
                    :item-within="true"
                    :parent-combo-item="parentComboItem"
                    :parent-combo-item-option="parentComboItemOption"
                    :choice-id="choiceId"
                    :adder-pricing-size="adderPricingSize"
                />
                <div class="divide-y-2 border-x-2 border-b-2 md:divide-y-0" v-if="$store.ready">
                    <div class="space-y-4 p-4 md:p-0">
                        <SelectionSummary
                            class="md:border-t-2 md:p-4"
                            v-if="
                                (menuItem?.type !== 'simple' || menuItem.type !== 'combination') &&
                                showDisplaySummary
                            "
                            v-bind="{ menuItem, quantities }"
                        />

                        <div
                            class="text-xl font-semibold uppercase text-palmers-charcoal-grey md:hidden"
                        >
                            Total for this item:
                        </div>
                        <div class="text-right text-3xl font-bold md:hidden">
                            {{ formatCurrency(totalPriceWithoutChoice + choicePrice) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { Head, Link } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import AddressCard from '../Partials/AddressCard.vue';
import { default as Button } from '@/customer/Components/BaseButton.vue';
import { useLang } from '@/customer/Composables';
import PaymentMethodCard from '../Partials/PaymentMethodCard.vue';

const { lang } = useLang();

const props = defineProps({
    paymentMethods: {
        type: Object,
    },
});

const paymentMethods = computed(() => props.paymentMethods.data);
</script>

<template>
    <AccountSettingsLayout :title="lang.title_index" class="px-2 pb-6 md:pb-0">
        <Head title="Profile" />

        <div class="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <template v-for="paymentMethod in paymentMethods">
                <PaymentMethodCard v-bind="{ ...paymentMethod }" />
            </template>
        </div>

        <Button :as="Link" :href="route('customer.profile.payment_methods.create')">
            Add Payment Method
        </Button>
    </AccountSettingsLayout>
</template>

<script setup>
import { useMenuItemStore } from '@/customer/Composables';
import { GridItem } from '.';
import { RadioOptionProps } from '@/customer/Components';
import { computed } from 'vue';

const emit = defineEmits(['update:quantities']);
const $store = useMenuItemStore();

const props = defineProps({
    ...RadioOptionProps,
    configuration: {
        type: Object,
        required: false,
    },
});

const computedQuantity = computed({
    get() {
        return $store.getQuantity(props.option.id);
    },
    set($event) {
        const newQuantities = {
            ...props.quantities,
            [props.option.id]: $event,
        };
        emit('update:quantities', newQuantities);
    },
});
</script>

<template>
    <GridItem
        :selected="checked"
        :included="option.included"
        :customizable="option.customizable"
        :price="option.price"
        v-model="computedQuantity"
        :choice="option.choice"
        choice-name-section-class="text-center font-bold"
        image-section-class="relative w-full items-center justify-center"
        quantity-section-class="absolute space-y-3 left-0 right-0 [@media(width<1040px)]:px-0 px-3 pt-1.5 bottom-4 w-full"
    />
</template>

<script setup>
import { computed, ref, watch, nextTick } from 'vue';
import { Mask } from 'maska';
import InputText from '@/customer/Components/InputText.vue';
import * as Cards from '@/common/Utils/cards';

const emit = defineEmits(['update:modelValue']);

const props = defineProps();

const inputValue = defineModel({
    set(value) {
        return (value ?? '').replaceAll(/\s/g, '');
    },
});

const maskedValue = defineModel('masked', {
    set(value) {
        inputValue.value = value;
        return value;
    },
});

maskedValue.value = inputValue.value;

const predictedCardVendor = computed(() => Cards.guessCardVendor(props.modelValue));
const mostLikelyCardVendor = computed(() => Cards.detectCardVendor(props.modelValue));
const cardIcon = computed(() => Cards.cardIcon(props.modelValue));

const dynamicVendorPattern = computed(() => {
    if (predictedCardVendor.value === 'AMEX') {
        return '#### ###### #####';
    }
    return '#### #### #### ####';
});

const maskPattern = computed(() => {
    return dynamicVendorPattern.value;
});

const isNew = computed(() => route().current() !== 'customer.profile.payment_methods.edit');

defineExpose({ modelValue: inputValue });

const disabledModelValue = ref('XXXX XXXX ' + props.modelValue);
</script>

<template>
    <div class="relative">
        <InputText v-if="isNew"
            :mask-options="{
                mask: maskPattern,
            }"
            v-model="maskedValue"
            class="pl-16"
        />
        <InputText v-else
            v-model="disabledModelValue"
            disabled
            class="pl-16"
        />
        <div class="pointer-events-none absolute inset-y-0 flex items-center pl-6">
            <div>
                <component
                    :is="cardIcon"
                    class="h-5"
                    :class="mostLikelyCardVendor ? 'opacity-100' : 'opacity-50'"
                />
            </div>
        </div>
    </div>
</template>

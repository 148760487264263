<script setup>
import { computed, ref, watch } from "vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from "@headlessui/vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import InputLabel from "@/customer/Components/InputLabel.vue";
import InputError from "@/customer/Components/InputError.vue";
import _ from "lodash";

const props = defineProps({
    options: {
        type: [Array, Object],
        default: () => [],
    },
    action: {
        type: Object,
        default: () => ({ label: "Add New", description: "" }),
    },
    hasAction: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        default: null,
    },
    type: {
        type: [Object, String],
        default: "text",
        required: false,
    },
    labelKey: { type: String, default: "label" },
    valueKey: { type: String, default: "value" },
    label: { type: [String, Boolean], default: "Select..." },
    labelClass: { type: String, default: null },
    nullable: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    modelValue: { required: true },
    placeholder: String,
    errorMessage: { type: String, default: null },
});

const emit = defineEmits(["update:modelValue", "act"]);

const search = ref("");

const filtered = computed(() =>
    search.value === ""
        ? props.options
        : _.toArray(props.options).filter((option) => {
              return option[props.labelKey]
                  ?.toLowerCase()
                  ?.includes(search.value.toLowerCase());
          }),
);

const selectedOption = () => {
    if (props.multiple) {
        return filtered.value.filter((option) =>
            props.modelValue.includes(option[props.valueKey]),
        );
    }

    return filtered.value.find(
        (option) => option[props.valueKey] === props.modelValue,
    );
};

const selection = computed({
    get: () => selectedOption(),
    set: (option) => {
        if (props.multiple) {
            emit(
                "update:modelValue",
                option.map((opt) => opt[props.valueKey]),
            );

            return;
        }
        emit("update:modelValue", option?.[props.valueKey] || null);
    },
});

const displayValue = (option) => {
    if (props.multiple) {
        return option.map((opt) => opt[props.labelKey]).join(", ");
    }

    if (option) {
        return option[props.labelKey] || null;
    }

    return null;
};
</script>

<template>
    <Combobox
        v-slot="{ open }"
        v-model="selection"
        as="div"
        v-bind="{ nullable, multiple }"
    >
        <template v-if="label">
            <slot name="label">
                <InputLabel :for="name" :value="label" :class="labelClass">
                    <template #ending><slot name="label.ending"/></template>
                </InputLabel>
            </slot>
        </template>

        <div class="relative mt-1">
            <ComboboxButton as="div" class="relative">
                <ComboboxInput
                    :placeholder="placeholder"
                    class="block w-full border-palmers-dragon-fruit border-2 rounded-3xl px-6 py-3 text-base tracking-widest focus:border-palmers-dragon-fruit focus:ring-offset-2"
                    :display-value="displayValue"
                    :class="{ 'rounded-b-none': open && filtered.length > 0 }"
                    @change="search = $event.target.value"
                />

                <div
                    class="absolute inset-y-0 right-3 flex items-center px-2 rounded-r-md focus:outline-none"
                >
                    <ChevronUpDownIcon
                        class="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                    />
                </div>
            </ComboboxButton>

            <ComboboxOptions
                v-if="open && filtered.length > 0"
                class="absolute z-20 w-full py-1 mt-0.5 overflow-auto text-base bg-white shadow-lg rounded-b-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
                <ComboboxOption
                    v-if="hasAction"
                    v-slot="{ active, selected }"
                    @click.prevent.stop="$emit('act')"
                >
                    <li
                        :class="[
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active ? 'bg-gray-600 text-white' : 'text-gray-900',
                        ]"
                    >
                        <span :class="['block truncate']">
                            <slot
                                name="action.label"
                                v-bind="{ selected, active }"
                            >
                                <div class="flex items-center px-8 relative">
                                    <div
                                        class="absolute left-0 inset-y-0 flex items-center"
                                    >
                                        <PlusIcon class="w-4 h-4" />
                                    </div>
                                    <div>
                                        <div>{{ action.label }}</div>
                                        <div
                                            v-if="action.description"
                                            class="text-xs opacity-30 font-normal"
                                        >
                                            {{ action.description }}
                                        </div>
                                    </div>
                                </div>
                            </slot>
                        </span>
                    </li>
                </ComboboxOption>

                <ComboboxOption
                    v-for="(option, index) in filtered"
                    :key="`option${index}${option.value}`"
                    v-slot="{ active, selected }"
                    :value="option"
                >
                    <li
                        :class="[
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active ? 'bg-gray-600 text-white' : 'text-gray-900',
                        ]"
                    >
                        <span
                            :class="[
                                'block truncate',
                                selected && 'font-semibold',
                            ]"
                        >
                            <slot
                                name="option.label"
                                v-bind="{ option, selected, active, index }"
                            >
                                {{ option.label }}
                            </slot>
                        </span>

                        <span
                            v-if="selected"
                            :class="[
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-gray-600',
                            ]"
                        >
                            <CheckIcon class="w-5 h-5" aria-hidden="true" />
                        </span>
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>

        <slot name="errors" v-if="!type?.props?.errorMessage">
            <InputError :message="errorMessage" class="m-2 tracking-normal" />
        </slot>
    </Combobox>
</template>

<script setup>
import InputTextarea from '@/customer/Components/InputTextarea.vue';
import InputText from '@/customer/Components/InputText.vue';
import BaseButton from '@/customer/Components/BaseButton.vue';
import SaveForLater from './SaveForLater.vue';
import { useCartStore, useOrderStore, useApp } from '@/customer/Composables';
import { TipSelector } from '.';
import { InputGroup } from '@/customer/Components';
import { ref, watch, onBeforeMount } from 'vue';

const props = defineProps({
    fees: {
        type: [Array, Object],
    }
})
const cart = useCartStore();
const order = useOrderStore();
const discountAlert = ref(false);

const showSuccessMessage = ref(false);
const applyDeliveryFeeIfNeeded = () => {
    if (order.form.fulfillment_type === 'delivery' && cart.summary.subtotal.raw < props.fees.deliveryMax) {
        // if(!'delivery-fee' in cart.summary.adjustments) {
            order.applyDeliveryFee(parseFloat(props.fees.delivery));
        // }
    } else {
        if ('delivery-fee' in cart.summary.adjustments) {
            order.removeDeliveryFee();
        }
    }
};

onBeforeMount(() => {
    applyDeliveryFeeIfNeeded();
});

watch(
    () => order.form.fulfillment_type,
    () => {
        applyDeliveryFeeIfNeeded();
    },
);

watch(
    () => cart.summary.subtotal.raw,
    () => {
        applyDeliveryFeeIfNeeded();
    },
);

watch(
    () => cart.getCartAdjustmentLabel('discount'),
    (value) => {
        if (value !== null) {
            showSuccessMessage.value = true;
            discountAlert.value = true;
            setTimeout(() => {
                discountAlert.value = false;
            }, 4000);
        } else {
            showSuccessMessage.value = false;
        }
    },
);

const showSaveForLater = ref(false);
const handleSaveNameClose = () => {
    showSaveForLater.value = false;
};
const handleSaveNameSuccess = () => {
    setTimeout(() => {
        showSaveForLater.value = false;
    }, 2900);
};
</script>

<template>
    <div class="space-y-4">
        <div class="flex flex-col items-start justify-between md:flex-row md:items-center">
            <h1 class="text-xl font-bold uppercase text-palmers-charcoal-grey">Review Order</h1>
            <button
                class="mt-2 w-full rounded-full px-4 py-2 font-semibold uppercase text-palmers-charcoal-grey ring-2 ring-inset ring-palmers-dragon-fruit supports-hover:hover:bg-palmers-himalayan-pink supports-hover:hover:opacity-90 md:mt-0 md:w-auto"
                v-if="!showSaveForLater"
                @click="showSaveForLater = true"
            >
                {{ order.form.saved_name === null ? 'Save As Favorite' : 'Edit Saved Name' }}
            </button>
        </div>
        <SaveForLater
            @close="handleSaveNameClose"
            @success="handleSaveNameSuccess"
            :name="order.form.saved_name"
            v-if="showSaveForLater"
        />
        <TipSelector />
        <div>
            <div class="font-bold text-palmers-charcoal-grey">Discount Code:</div>
            <div class="mt-2 flex w-full">
                <InputGroup
                    type="text"
                    :error-message="cart.errors.code"
                    v-model="cart.discountCode"
                    :label="null"
                    class="flex-grow text-palmers-charcoal-grey"
                    error-class="text-palmers-dragonfruit"
                    name="discount"
                />
                <div>
                    <BaseButton
                        class="ml-2 mt-2 shadow-none"
                        @click="cart.applyDiscount(cart.discountCode)"
                        id="discount_apply"
                        >Apply</BaseButton
                    >
                </div>
            </div>
            <p
                v-if="showSuccessMessage === true"
                class="mb-6 mt-1.5 pl-2 text-sm text-palmers-charcoal-grey"
            >
                Applied Discount
            </p>
        </div>
        <div class="mt-4 text-palmers-charcoal-grey">
            <div class="flex items-center justify-between font-semibold">
                <div>Subtotal</div>
                <div>{{ cart.summary.subtotal.formatted }}</div>
            </div>

            <div
                class="flex items-center justify-between font-semibold"
                :class="{
                    'animate-pulse': discountAlert,
                }"
            >
                <div>
                    Discount {{ cart.getCartAdjustmentLabel('discount') }}
                    <span
                        v-if="cart.getCartAdjustmentLabel('discount')"
                        @click="cart.removeDiscount"
                        class="text-xs text-red-600 underline supports-hover:hover:cursor-pointer"
                        >Remove</span
                    >
                </div>
                <div>{{ cart.getCartAdjustmentPrice('discount') }}</div>
            </div>
            <div class="flex items-center justify-between font-semibold">
                <div>Tax</div>
                <div>{{ cart.getCartAdjustmentPrice('tax') }}</div>
            </div>
            <div class="flex items-center justify-between font-semibold">
                <div>Tip {{ cart.getCartAdjustmentLabel('tip') }}</div>
                <div>{{ cart.getCartAdjustmentPrice('tip') }}</div>
            </div>
            <div class="flex items-center justify-between font-semibold">
                <div>Fee</div>
                <div>{{ cart.getCartAdjustmentPrice('cc-rate') }}</div>
            </div>
            <div
                v-if="
                    order.form.fulfillment_type == 'delivery' &&
                    cart.summary.subtotal.raw < fees.deliveryMax
                "
                class="flex items-center justify-between font-semibold"
            >
                <div>{{ cart.getCartAdjustmentLabel('delivery-fee') }}</div>
                <div>{{ cart.getCartAdjustmentPrice('delivery-fee') }}</div>
            </div>

            <div class="flex items-center justify-between text-xl font-bold">
                <div>Total</div>
                <div>{{ cart.summary.total.formatted }}</div>
            </div>
        </div>
    </div>
</template>

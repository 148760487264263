<script setup>
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { onBeforeMount, onMounted, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import moment from 'moment';
import { useMenuItemStore, useCartStore } from '@/customer/Composables';
import { Link, Head } from '@inertiajs/vue3';
import SpinnerIcon from '@/customer/Components/Icons/SpinnerIcon.vue';

const menuItemStore = useMenuItemStore();
const cartStore = useCartStore();

const page = usePage();

const pastOrders = ref(page.props.pastOrders.pageResults);
const currentPage = ref(Number(page.props.pastOrders.currentPage));
const totalPages = ref(
    Array.from({ length: Number(page.props.pastOrders.totalPages) }, (v, k) => k + 1),
);
const totalItems = ref(Number(page.props.pastOrders.totalItems));
const itemsPerPage = ref(Number(page.props.pastOrders.itemsPerPage));

import ComboItemDifferences from '../Order/Partials/ComboItemDifferences.vue';
import ItemDifferences from '../Order/Partials/ItemDifferences.vue';
import ItemCustomerInfo from '../Order/Partials/ItemCustomerInfo.vue';

const accordionOpen = ref([]);
onBeforeMount(() => pastOrders.value.forEach(() => accordionOpen.value.push(false)));

const parseReadyAt = (value, method, index) => {
    const getTime = (isoDate) => {
        const originalDate = new Date(isoDate);
        let hours = originalDate.getHours();
        const minutes = originalDate.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const strHours = hours.toString().padStart(2, '0');
        return `${strHours}:${minutes} ${ampm}`;
    };

    const getDate = (date) => {
        return date.format('dddd, MMMM Do YYYY');
    };

    if (method === 'date') {
        if (value === null) {
            return getDate(moment.utc(pastOrders.value[index].updated_at));
        }
        return getDate(moment.utc(value));
    }

    if (method === 'time') {
        if (value === null) {
            return 'ASAP';
        }
        return getTime(moment.utc(value));
    }

    return 'N/A';
};

const handleAccordion = (newIndex) => {
    accordionOpen.value[newIndex] = !accordionOpen.value[newIndex];
    accordionOpen.value.forEach((value, index) => {
        if (value && index !== newIndex) {
            accordionOpen.value[index] = false;
        }
    });
};

const formatCartObject = (arg, quantity = null) => {
    return {
        innerConfigQuantities: arg.snapshot.meta.innerConfigQuantities,
        instructions: arg.snapshot.meta.instructions,
        name: arg.snapshot.meta.name,
        quantities: arg.snapshot.meta.quantities,
        size: arg.snapshot.meta.size_id,
        slug: arg.snapshot.meta.slug,
        quantity: quantity ?? arg.snapshot.quantity,
        single_size: false,
    };
};

const loading = ref([]);

onBeforeMount(() => pastOrders.value.forEach(() => loading.value.push(false)));

const reorderItem = async (arg, index) => {
    loading.value[index] = true;
    const addToCartObj = formatCartObject(arg, 1);
    await menuItemStore.addToCart(1, addToCartObj);
    loading.value[index] = false;
    cartStore.open();
};

const orderAgain = async (arg, index) => {
    loading.value[index] = true;
    const orderItems = arg.raw_items.order_items;
    const items = [];
    orderItems.forEach((item) => {
        const addToCartObj = formatCartObject(item);
        items.push(addToCartObj);
    });
    await menuItemStore.addToCart(1, items);
    loading.value[index] = false;
    cartStore.open();
};
</script>

<template>
    <Head title="Past" />
    <main class="mx-auto w-full max-w-screen-md py-6 md:py-0">
        <div class="flex w-full items-end justify-between">
            <p class="text-lg font-bold uppercase text-black">
                Past Orders<span class="pl-1 text-gray-300">{{ totalItems }}</span>
            </p>
            <p
                v-if="Array.isArray(pastOrders) && pastOrders.length > 0"
                class="text-sm font-medium uppercase text-gray-300"
            >
                Showing {{ (currentPage - 1) * itemsPerPage + 1 }}-{{
                    (currentPage - 1) * itemsPerPage + pastOrders.length
                }}
            </p>
        </div>
        <section class="mt-6 flex w-full flex-col gap-y-4">
            <template v-if="Array.isArray(pastOrders) && pastOrders.length > 0">
                <div
                    v-for="(order, index) in pastOrders"
                    :id="`orderHistory${index}`"
                    class="relative h-auto overflow-hidden border px-0 shadow-sm transition-all duration-[300ms]"
                    :class="{ 'rounded-xl border-palmers-dragon-fruit': accordionOpen[index] }"
                >
                    <div
                        v-if="loading[index]"
                        class="absolute inset-0 z-[100] flex items-center justify-center bg-palmers-mush-grey/20 shadow-md transition-all duration-[300ms]"
                    >
                        <SpinnerIcon class="size-[6rem] animate-spin text-palmers-dragon-fruit" />
                    </div>
                    <div class="relative z-10 space-y-2 bg-white py-2 text-palmers-charcoal-grey">
                        <div class="flex flex-row-reverse items-start justify-between pl-2 pr-4">
                            <div class="flex flex-col items-end">
                                <p class="text-lg font-bold">
                                    {{ parseReadyAt(order.ready_at, 'date', index) }}
                                </p>
                                <p class="leading-none">
                                    {{ parseReadyAt(order.ready_at, 'time', index) }}
                                </p>
                            </div>
                            <div class="flex items-center gap-x-2">
                                <button
                                    v-if="menuItemStore.ready"
                                    @click="orderAgain(order, index)"
                                    class="hover border px-3 py-2 font-semibold uppercase leading-[16px] transition-all duration-[300ms]"
                                    :class="[
                                        accordionOpen[index]
                                            ? 'supports-hover:hover:bg-palmers-himalayan-pink rounded-lg border-palmers-dragon-fruit text-palmers-dragon-fruit active:border-palmers-avocado-green active:bg-palmers-avocado-green active:text-white'
                                            : 'supports-hover:hover:text-palmers-charcoal-grey/80 rounded-sm border-gray-200 bg-gray-200 text-palmers-charcoal-grey',
                                    ]"
                                >
                                    Add All Items
                                </button>
                                <Link
                                    class="hover px-3 py-2 font-semibold uppercase leading-[16px] transition-all duration-[300ms]"
                                    :class="[
                                        accordionOpen[index]
                                            ? 'supports-hover:hover:bg-palmers-himalayan-pink rounded-lg border border-palmers-dragon-fruit text-palmers-dragon-fruit active:border-palmers-avocado-green active:bg-palmers-avocado-green active:text-white'
                                            : 'supports-hover:hover:text-palmers-charcoal-grey/80 rounded-sm bg-gray-200 text-palmers-charcoal-grey',
                                    ]"
                                    v-else
                                    :href="menuItemStore.startOrder"
                                >
                                    Start Order
                                </Link>
                                <p class="font-medium">Order#{{ order.order_number }}</p>
                            </div>
                        </div>
                        <div class="flex flex-row-reverse items-end justify-between px-4">
                            <p class="font-semibold uppercase">
                                {{ order.fulfillment_type }}
                            </p>
                            <p class="text-2xl font-bold">${{ order.total }}</p>
                        </div>
                    </div>
                    <div
                        @click="handleAccordion(index)"
                        class="group relative z-10 flex w-full cursor-pointer items-center justify-between px-4 py-2 transition-all duration-[200ms]"
                        :class="[
                            accordionOpen[index]
                                ? 'bg-palmers-dragon-fruit text-white'
                                : 'bg-gray-200',
                        ]"
                    >
                        <p class="supports-hover:group-hover:opacity-70 font-bold uppercase">
                            Items
                        </p>
                        <ChevronDownIcon
                            :class="{ 'rotate-180': accordionOpen[index] }"
                            class="supports-hover:group-hover:opacity-70 mr-2 size-5 stroke-[3] transition-all duration-[200ms]"
                        />
                    </div>
                    <div
                        class="transition-all duration-[300ms]"
                        :class="[accordionOpen[index] ? 'h-auto' : 'h-0']"
                    >
                        <div
                            :class="[
                                accordionOpen[index] ? 'translate-y-0' : '-translate-y-[100%]',
                            ]"
                            class="relative z-0 space-y-1 divide-y bg-palmers-white pb-1 pt-2 transition-all duration-[300ms]"
                        >
                            <div
                                v-for="(item, jdex) in order.formatted_items"
                                class="px-4 py-2 first:pt-0"
                            >
                                <div
                                    class="flex items-center justify-between text-xl font-semibold uppercase text-palmers-charcoal-grey"
                                >
                                    <p>{{ item.item_name }}</p>
                                    <p>
                                        <span class="text-sm lowercase">x</span>{{ item.quantity }}
                                    </p>
                                </div>
                                <p
                                    v-if="item.size"
                                    class="text-md font-semibold uppercase leading-none text-palmers-charcoal-grey/60"
                                >
                                    {{ item.size }}
                                </p>
                                <div class="flex flex-col gap-y-2">
                                    <ComboItemDifferences
                                        v-if="item.is_combo"
                                        :comboItems="item.combo_items"
                                    />
                                    <ItemDifferences v-else-if="item.mods" :mods="item.mods" />
                                    <ItemCustomerInfo
                                        :name="item.customer_name"
                                        :instructions="item.instructions"
                                    />
                                    <button
                                        v-if="menuItemStore.ready"
                                        @click="
                                            () =>
                                                reorderItem(
                                                    order.raw_items.order_items[jdex],
                                                    index,
                                                )
                                        "
                                        class="supports-hover:hover:bg-palmers-himalayan-pink w-fit cursor-pointer rounded-lg border border-palmers-dragon-fruit px-3 py-2 font-semibold uppercase leading-none text-palmers-dragon-fruit transition-all duration-[300ms] active:border-palmers-avocado-green active:bg-palmers-avocado-green active:text-white"
                                    >
                                        Add Item
                                    </button>
                                    <Link
                                        class="supports-hover:hover:bg-palmers-himalayan-pink w-fit cursor-pointer rounded-lg border border-palmers-dragon-fruit px-3 py-2 font-semibold uppercase leading-none text-palmers-dragon-fruit transition-all duration-[300ms] active:border-palmers-avocado-green active:bg-palmers-avocado-green active:text-white"
                                        v-else
                                        :href="menuItemStore.startOrder"
                                    >
                                        Start Order
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div
                v-else
                class="flex w-full flex-col items-center justify-center gap-y-2 border-2 border-gray-300 py-10"
            >
                <p class="text-lg font-bold uppercase text-palmers-charcoal-grey">
                    No orders placed yet
                </p>
                <p class="text-md">Aren't you hungry?</p>
                <Link :href="menuItemStore.startOrder" class="text-sm underline opacity-60"
                    >Let's change that</Link
                >
            </div>
            <div
                class="mx-auto mt-3 flex gap-x-5 px-6 text-xl font-medium text-palmers-charcoal-grey"
            >
                <template v-for="item in totalPages">
                    <a
                        :href="currentPage === item ? null : `/profile/past-orders/${item}`"
                        class="flex size-8 items-center justify-center rounded-full"
                        :class="[
                            currentPage === item
                                ? 'cursor-default bg-palmers-dragon-fruit text-white'
                                : 'supports-hover:hover:underline cursor-pointer',
                        ]"
                        >{{ item }}</a
                    ></template
                >
            </div>
        </section>
    </main>
</template>

<style>
.v-enter-active {
    transition: all 0.3s ease-out;
}

.v-enter-from,
.v-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}
</style>

<script setup>
import { CheckboxInput } from '@/customer/Components';
import { useCheckoutStore } from '@/customer/Composables';

const store = useCheckoutStore();

</script>

<template>
    <div class="rounded-xl border-2 border-palmers-charcoal-grey bg-white p-6 shadow-lg">
        <div class="flex">
            <CheckboxInput
                input-class="!text-amber-600"
                v-model="store.utensils"
                label="Utensils, napkins, plates, and condiments?"
            />
        </div>
    </div>
</template>

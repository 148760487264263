<script setup>
import { watch, ref, computed } from 'vue';
import { Link } from '@inertiajs/vue3';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import { HomeIcon } from '@heroicons/vue/24/solid';
import { useMenuItemStore } from '@/customer/Composables';
import { usePage } from '@inertiajs/vue3';

const $store = useMenuItemStore();

const props = defineProps({
    breadcrumbs: {
        type: Object,
        default: null,
    },
});

const crumbArr = ref([]);

const isProfile = computed(() =>
    crumbArr.value.length > 0 ? crumbArr.value[0].name === 'my-account' : false,
);

const formatBreadcrumbs = (obj) => {
    if (obj.label !== null) {
        if (crumbArr.value[crumbArr.value.length - 1]?.label !== obj.label) {
            crumbArr.value.push(obj);
        }
        // specific soups breadcrumb removal
        if (crumbArr.value[crumbArr.value.length - 1]?.label !== 'Soups') {
            crumbArr.value = crumbArr.value.filter((crumb) => crumb.label !== 'Soups');
        }
    }

    const undefinedCheck = isProfile.value ? obj.options.find((opt) => opt.match === true) : true;

    if (Array.isArray(obj.options) && obj.options.length !== 0 && undefinedCheck !== undefined) {
        const nextObj = isProfile.value
            ? obj.options.find((opt) => opt.match === true)
            : obj.options[0];
        formatBreadcrumbs(nextObj);
    } else {
        if (
            obj.options.map((opt) => {
                if (opt.options.find((opt) => opt.match === true) !== undefined) {
                    formatBreadcrumbs(opt);
                }
            })
        )
            !isProfile.value && $store.ready ? crumbArr.value.shift() : null;
        return;
    }
};

watch(
    () => props.breadcrumbs,
    (newVal) => {
        if (newVal) {
            crumbArr.value = [];
            formatBreadcrumbs(newVal);
        }
    },
    { deep: true, immediate: true },
);

// const breadcrumbsRef = ref(null);
// const breadcrumbOffset = ref(0);

// onMounted(() => {
//     const crumbWidth = breadcrumbsRef.value.getBoundingClientRect().width;
//     const windowWidth = window.innerWidth;
//     if (crumbWidth > windowWidth) {
//         breadcrumbOffset.value = crumbWidth - windowWidth;
//     }
// });
</script>

<template>
    <section
        class="flex w-full items-center gap-x-2 overflow-y-auto bg-palmers-havarti px-4 py-2 sm:mx-auto sm:px-6"
        ref="breadcrumbsRef"
        v-if="props.breadcrumbs"
    >
        <a
            :href="route('customer.order.start')"
            class="cursor-pointer border-b-2 border-transparent supports-hover:hover:border-black"
        >
            <span class="flex items-center p-px">
                <HomeIcon class="h-5 w-5" />
            </span>
        </a>
        <div class="flex items-center gap-1" v-for="(breadcrumb, index) in crumbArr">
            <ChevronRightIcon class="h-4 w-4 stroke-[3]" />
            <a
                :href="route(breadcrumb.route, route().params)"
                class="mt-[.05rem] text-nowrap border-b-2 border-transparent capitalize supports-hover:hover:border-black"
                :class="[
                    index === crumbArr.length - 1
                        ? 'pointer-events-none font-light'
                        : 'font-semibold',
                ]"
            >
                {{ breadcrumb.label.toLowerCase() }}
            </a>
        </div>
    </section>
</template>

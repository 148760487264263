<script setup>
import { PhotoIcon } from '@heroicons/vue/20/solid';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import { Head, router } from '@inertiajs/vue3';
import { MenuItem } from './Partials';
import { onBeforeMount, onMounted, ref } from 'vue';
import Spinner from '@/customer/Components/UI/Progress/Spinner.vue';
import { useOrderStore } from '@/customer/Composables';

const props = defineProps({
    menus: { type: Object },
    location: { type: String },
});

onMounted(() => window.print())

</script>

<template>
    <Head :title="location + ' Menu'" />
    <main class="padding-before-print px-4 py-8 flex justify-center">
        <div class="mx-10 border p-4 text-left">
            <h1 class="font-bold text-3xl pb-4">Palmer's Deli and Market - {{ location }} Menu</h1>
            <div class="pb-5" v-for="(menu, i) of menus.data" :key="i">
                <h2 class="font-semibold text-xl">{{ menu.name }}</h2>
                    <p class="pb-4 text-md">{{ menu.description }}</p>
                    <div v-for="(menuItem, key) of menu.menuItems" :key="key">
                        <div class="flex justify-between">
                            <h3>{{ menuItem.name }}</h3>
                            <div class="flex justify-end">
                                <div v-for="(price, key, index) in menuItem.sizePricing" :key="index">
                                    <span v-if="Object.keys(menuItem.sizePricing).length > 1">
                                        {{ price.size.label }}
                                    </span>
                                    <span class="italics text-xs" v-if="price.amount == 0">
                                        Customize for Price
                                    </span>
                                    <span v-else>
                                        ${{ price.amount }}
                                    </span>
                                     <span class="mx-1" v-if="index < Object.keys(menuItem.sizePricing).length - 1">|</span>
                                </div>
                            </div>
                        </div>
                        <p class="text-xs italic pb-4">{{ menuItem.description }}</p>
                    </div>
            </div>
        </div>
    </main>
</template>

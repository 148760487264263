<script setup>
import { inject, computed, ref } from 'vue';
import { useOrderStore } from '@/customer/Composables';
import { optionIcon, optionSwitcher, optionSwitchers } from './internal';
import { ErrorBoundary } from '@/common/Components';
import Modal from '@/customer/Pages/Checkout/Partials/OrderSummaryModal/Modal.vue';
import { Link } from '@inertiajs/vue3';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';

const routeIsOverview = computed(() => route().current() === 'customer.order.overview');

const order = useOrderStore();

const optionValue = (option) => {
    const value = order.progress[option];
    return typeof value === 'string' ? value : option;
};

const optionValueLabel = (option) =>
    order.options[option]['options'].find(({ value }) => value === optionValue(option))?.display;

const progressList = computed(() =>
    Object.keys(optionSwitchers).filter((name) => order.form[name] != null),
);

const modalData = ref({
    open: false,
    step: '',
});

const handleModal = (open, step = '') => {
    if (order.inProgress) {
        currentMobileComponent.value = null;
        currentMobileComponentIndex.value = null;
        document.body.style.overflow = open ? 'hidden' : '';
        modalData.value.step = step;
        modalData.value.open = open;
    }
};

const modalComponents = ref(['fulfillment_type', 'ready_type']);

const currentMobileComponent = ref(null);
const currentMobileComponentIndex = ref(null);

const handleMobileComponent = (newComponent, index) => {
    if (order.inProgress) {
        progressBannerHeight.value = progressBanner?.value?.getBoundingClientRect().height ?? 0;
        if (index === currentMobileComponentIndex.value) {
            currentMobileComponent.value = null;
            currentMobileComponentIndex.value = null;
        } else {
            currentMobileComponent.value = newComponent;
            currentMobileComponentIndex.value = index;
        }
    }
};

const progressBanner = ref(null);
const progressBannerHeight = ref(0);

const startOver = () => {
    currentMobileComponent.value = null;
    currentMobileComponentIndex.value = null;
    order.restart();
};
</script>

<template>
    <div
        v-if="order.progress.location && !routeIsOverview"
        ref="progressBanner"
        class="relative flex flex-wrap items-center justify-center gap-x-0 bg-pink pb-2 pt-3 text-sm font-bold text-white sm:gap-y-1 sm:pb-3 sm:pt-5 lg:gap-x-10"
    >
        <Link
            v-if="order.inProgress"
            :href="order.startOverHref"
            @success="startOver"
            class="flex flex-row items-center space-x-3 rounded-md border border-transparent p-1 px-3"
        >
            <ArrowPathIcon class="size-4 text-white" />
            <div>Start Over</div>
        </Link>
        <template v-for="(option, index) in progressList">
            <ErrorBoundary>
                <component
                    :class="{ 'col-span-2': index === 0 }"
                    :is="optionSwitcher(option)"
                    :icon="optionIcon(optionValue(option))"
                    :label="optionValueLabel(option)"
                    @open-modal="
                        modalComponents.indexOf(option) !== -1
                            ? handleModal(true, option)
                            : undefined
                    "
                    @responsive-dialog="(e) => handleMobileComponent(e, index)"
                    @start-over="startOver"
                />
            </ErrorBoundary>
        </template>
        <div
            v-show="currentMobileComponent"
            :style="`top: ${progressBannerHeight}px;`"
            class="absolute z-[100] w-full overflow-hidden rounded-b-3xl bg-palmers-white text-black shadow-sm ring-1 ring-palmers-dragon-fruit [@media(width>894px)]:hidden"
        >
            <component :is="currentMobileComponent" />
        </div>
    </div>
    <Modal
        @close-modal="handleModal(false)"
        :step="modalData.step"
        in-component="OrderProgressBanner"
        v-if="modalData.open"
    />
    <!-- <div class="fixed bottom-0 left-0 right-0 top-0 z-[1000] bg-palmers-charcoal-grey/60"></div> -->
</template>

<script setup>
import ConfirmationLink from '@/common/Components/UI/Alerts/ConfirmationLink.vue';
import { Link } from '@inertiajs/vue3';
import { useLang } from '@/customer/Composables';
import { CreditCardIcon, UserCircleIcon, CalendarIcon } from '@heroicons/vue/24/outline';

const { lang } = useLang();

const props = defineProps({
    id: { type: Number },
    first_name: { type: String },
    last_name: { type: String },
    address: { type: Object },
    authnet_pm_expiration: { type: String },
    authnet_pm_last_four: { type: String },
    authnet_pm_type: { type: String },
});
</script>

<template>
    <div class="rounded border p-4">
        <div class="flex justify-start">
            <UserCircleIcon class="mr-2 w-4" /> Name: {{ first_name }} {{ last_name }}
        </div>
        <div class="flex justify-start">
            <CreditCardIcon class="mr-2 w-4" /> Card #: {{ authnet_pm_last_four }}
        </div>
        <div class="flex justify-start">
            <CalendarIcon class="mr-2 w-4" /> Expiration: {{ authnet_pm_expiration }}
        </div>
        <div class="flex items-center space-x-3 text-sm">
            <Link
                :href="route('customer.profile.payment_methods.edit', id)"
                class="font-medium underline"
            >
                Edit
            </Link>

            <ConfirmationLink
                v-slot="{ show }"
                :href="route('customer.profile.payment_methods.destroy', id)"
                method="DELETE"
            >
                <button
                    @click.prevent="show"
                    class="text-left font-medium text-palmers-burnt-orange underline"
                >
                    Delete
                </button>
            </ConfirmationLink>
        </div>
    </div>
</template>

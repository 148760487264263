<script setup>
import { ref } from 'vue';
import FooterList from './Footer/FooterList.vue';
import ApplicationLogo from '@/customer/Components/ApplicationLogo.vue';
import AppIcon from '@/customer/Components/AppIcon.vue';
import useApp from '@/customer/Composables/useApp';
import { MenuType } from '@/common/Utils/enums.js';
import moment from 'moment';
import { Link } from '@inertiajs/vue3';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline';

const { social: socialLinks, copyright, policies: policyLinks, footer } = useApp();
const linksShown = ref([]);
const showLinks = (link) => {
    if (linksShown.value[link.label] == true) {
        linksShown.value[link.label] = false;
    } else {
        linksShown.value[link.label] = true;
    }
};
</script>

<template>
    <footer id="mainFooter" class="border-t-pinstripe z-50 space-y-6 bg-palmers-havarti pb-12">
        <div
            class="mx-auto flex w-full max-w-screen-2xl flex-col items-center space-y-6 px-6 py-8 sm:grid sm:grid-cols-5 sm:items-start sm:gap-8 sm:space-y-0"
        >
            <div class="">
                <ApplicationLogo class="h-24 w-auto sm:h-16" />
            </div>

            <FooterList label="Locations">
                <li>
                    <a href="https://palmersdeliandmarket.com/locations/" target="__blank"
                        >Ingersoll</a
                    >
                </li>
                <li>
                    <a href="https://palmersdeliandmarket.com/locations/" target="__blank"
                        >Kaleidoscope</a
                    >
                </li>
                <li>
                    <a href="https://palmersdeliandmarket.com/locations/" target="__blank"
                        >Urbandale</a
                    >
                </li>
                <li>
                    <a href="https://palmersdeliandmarket.com/locations/" target="__blank"
                        >West Des Moines</a
                    >
                </li>
            </FooterList>

            <FooterList label="Menus & More">
                <li v-for="link in footer.menus_and_more">
                    <template v-if="link.links">
                        <p
                            class="flex cursor-pointer items-center justify-start"
                            @click="showLinks(link)"
                        >
                            {{ link.label }}
                            <ChevronDownIcon v-if="!linksShown[link.label]" class="ml-1 h-3 w-3" />
                            <ChevronUpIcon v-if="linksShown[link.label]" class="ml-1 h-3 w-3" />
                        </p>
                        <div
                            :id="link.label"
                            v-if="linksShown[link.label]"
                            v-for="innerLink in link.links"
                            class="ml-2"
                        >
                            <template v-if="!innerLink.target || innerLink.target === '_self'">
                                <Link :href="innerLink.href">{{ innerLink.label }}</Link>
                            </template>
                            <template v-else>
                                <a :href="innerLink.href" :target="innerLink.target">{{
                                    innerLink.label
                                }}</a>
                            </template>
                        </div>
                    </template>
                    <template v-else-if="!link.target || link.target === '_self'">
                        <Link :href="link.href">{{ link.label }}</Link>
                    </template>
                    <template v-else>
                        <a :href="link.href" :target="link.target">{{ link.label }}</a>
                    </template>
                </li>
            </FooterList>

            <FooterList label="Support & Services">
                <li>
                    <a href="https://palmersdeliandmarket.com/about-us/" target="__blank"
                        >About Us</a
                    >
                </li>
                <li>
                    <a href="https://palmersdeliandmarket.com/careers/" target="__blank">Careers</a>
                </li>
                <li>
                    <a href="https://palmersdeliandmarket.com/contact-us/" target="__blank"
                        >Contact Us</a
                    >
                </li>
            </FooterList>

            <FooterList label="Let's Get Social">
                <template #list>
                    <ul class="flex items-center space-x-3">
                        <template
                            v-for="({ icon, url }, index) in socialLinks"
                            :key="`social${index}`"
                        >
                            <li>
                                <a :href="url" target="_blank">
                                    <AppIcon
                                        v-if="icon"
                                        :icon="icon"
                                        class="h-6 w-6 text-palmers-avocado-green"
                                    />
                                </a>
                            </li>
                        </template>
                    </ul>
                </template>
            </FooterList>
        </div>

        <div
            class="flex flex-col items-center space-x-1 space-y-3 px-1 py-1 sm:flex-row sm:justify-center sm:space-y-0 sm:px-6"
        >
            <small class="text-xs">
                {{ copyright }}
            </small>

            <ul class="inline-flex divide-x divide-gray-400 text-xs">
                <li
                    class="inline-block px-3 text-center"
                    v-for="({ label, url }, index) in policyLinks"
                    :key="`policy${index}`"
                >
                    <a
                        :href="url"
                        target="_blank"
                        class="supports-hover:hover:underline"
                        :title="label"
                    >
                        {{ label }}
                    </a>
                    <!-- <a :href="url" class="supports-hover:hover:underline" :title="label">
                        {{ label }}
                    </a> -->
                </li>
            </ul>
        </div>
    </footer>
</template>

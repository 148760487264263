<script setup>
import InlineSvg from 'vue-inline-svg';
import useApp from '@/customer/Composables/useApp';

const { app } = useApp();

/**
 * DISCLAIMER
 * Was previously checking app.logo.is_svg bool
 * to use InlineSvg or img, InlineSvg was
 * not working anymore and img for svg is fine
 */
</script>

<template>
    <component :is="false ? InlineSvg : 'img'" :src="app.logo?.company_url" />
</template>

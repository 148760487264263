<script setup>
import { Head, router, useForm, usePage } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import { useAuthNetStore, useCheckoutStore, useLang } from '@/customer/Composables';
import PaymentMethodForm from '../Partials/PaymentMethodForm.vue';

const { lang } = useLang();

const props = defineProps({
    billingAddresses: { type: Object },
    authnet: { type: Object}
});

const authnet = useAuthNetStore();
authnet.initialize();

const form = useForm(route().current(), {
    first_name: null,
    last_name: null,
    card_number: null,
    card_type: null,
    card_code: null,
    expiration: null,
    month: null,
    year: null,
    address: null,
    create_new_address: false
});

async function submit() {
    //validate form all filled out
    const address = typeof form.address == 'object' ? form.address : props.billingAddresses?.data?.find((address) => address.id == form.address);
    authnet.responseData.saveForLater = true;
    authnet.requestData.cardData = {
        cardCode: form.card_code,
        cardNumber: form.card_number,
        fullName: form.first_name + ' ' + form.last_name,
        month: form.month,
        year: form.year,
        zip: address.zip,
    }

    try {
        const response = await authnet.submit()
        router.post(route('customer.profile.payment_methods.store'), {
            ...response.opaqueData,
            first_name: form.first_name,
            last_name: form.last_name,
            create_new_address: form.create_new_address,
            address: form.address,
        })
    } catch (error) {
        console.error(error)
    }
};

</script>

<template>
    <AccountSettingsLayout :title="lang.title_add" class="pb-6 md:pb-0">
        <Head title="Profile" />

        <PaymentMethodForm v-bind="{ form }" :billing-addresses="billingAddresses.data" @submit="submit" />
    </AccountSettingsLayout>
</template>

<script setup>
import Accordion from '@/customer/Components/UI/Accordion/Accordion.vue';
import {
    CheckboxGroupInput,
    CheckboxGroupInputSingle,
    RadioGroupInput,
} from '@/customer/Components';
import { EmptyBoundary } from '@/common/Components';
import { InputGroup } from '@/company/Components';
import { computed } from 'vue';
import { mapValues } from 'lodash';
import { useMenuItemStore, useOrderStore } from '@/customer/Composables';
import { arrToObj } from '@/common/Utils';
import Select from '@/customer/Components/UI/Input/Select/Select.vue';

const emit = defineEmits(['update:selections', 'update:quantities']);

const $store = useMenuItemStore();
const orderStore = useOrderStore();

const props = defineProps({
    configuration: {
        type: Object,
        default: () => ({}),
    },
    selections: {
        type: [Object, Array],
        default: () => [],
    },
    quantities: {
        type: [Object, Array],
        default: () => [],
    },
    expanded: {
        type: Boolean,
        required: true,
    },
    handleAccordion: {
        type: Function,
        required: true,
    },
    itemWithin: {
        type: Boolean,
        required: false,
        default: false,
    },
    itemWithinWithin: {
        type: Boolean,
        required: false,
        default: false,
    },
    withinWithinConfigId: {
        type: [Number, String],
        required: false,
        default: 0,
    },
    parentComboItem: {
        type: Number,
        required: false,
        default: 0,
    },
    parentComboItemOption: {
        type: Number,
        required: false,
        default: 0,
    },
    choiceId: {
        type: Number,
        required: false,
        default: 0,
    },
    adderPricingSize: {
        type: Number,
        required: false,
        default: 0,
    },
    name: {
        type: String,
    },
    hideUpgrades: {
        type: Boolean,
    },
});

function flattenNestedObject(obj) {
    const result = {};

    for (const outerKey in obj) {
        if (obj.hasOwnProperty(outerKey)) {
            result[outerKey] = {};
            for (const innerKey in obj[outerKey]) {
                if (obj[outerKey].hasOwnProperty(innerKey)) {
                    const nestedObj = obj[outerKey][innerKey];
                    for (const key in nestedObj) {
                        if (nestedObj.hasOwnProperty(key)) {
                            result[outerKey][key] = nestedObj[key];
                        }
                    }
                }
            }
        }
    }

    return result;
}

const configChoices = computed(() => {
    return props.configuration.choices.filter((choice) => {
        if (choice.activeLocations) {
            return choice.activeLocations.includes(orderStore.selectedLocation?.id);
        }
        return true;
    });
});

const comboChoices = computed(() => {
    return props.configuration.items.filter((choice) => {
        if (choice.activeLocations) {
            return choice.activeLocations.includes(orderStore.selectedLocation?.id);
        }
        return true;
    });
});

const handleSelections = ($event) => {
    if (props.itemWithin) {
        const quantities = $store.getInnerConfigQuantities(props.choiceId, props.configuration);
        const newQuantities = mapValues(arrToObj([$event['value']]), (id) =>
            Math.max(quantities[id] ?? 1, 1),
        );
        $store.setInnerConfigQuantities(props.choiceId, props.configuration.id, newQuantities);
    } else if (props.itemWithinWithin) {
        const quantities = $store.getInnerConfigQuantities(
            props.withinWithinConfigId,
            props.configuration,
        );
        const newQuantities = mapValues(arrToObj([$event['value']]), (id) =>
            Math.max(quantities[id] ?? 1, 1),
        );
        $store.setInnerConfigQuantities(
            props.withinWithinConfigId,
            props.configuration.id,
            newQuantities,
        );
        let newOuterQuantity = {};
        newOuterQuantity[props.choiceId] = {
            ...$store.innerConfigQuantities[props.withinWithinConfigId],
        };
        $store.setConfigQuantities(
            props.parentComboItemOption,
            flattenNestedObject(newOuterQuantity),
        );
    } else {
        const quantities = $store.getConfigQuantities(props.configuration.id);
        const newQuantities = mapValues(arrToObj([$event['value']]), (id) =>
            Math.max(quantities[id] ?? 1, 1),
        );
        $store.setConfigQuantities(props.configuration.id, newQuantities);
    }
};

const handleQuantities = ($event) => {
    if (props.itemWithin) {
        const quantities = $store.getInnerConfigQuantities(props.choiceId, props.configuration);
        const newQuantities = Object.assign(quantities, $event);
        $store.setInnerConfigQuantities(props.choiceId, props.configuration.id, newQuantities);
    } else if (props.itemWithinWithin) {
        const quantities = $store.getInnerConfigQuantities(
            props.withinWithinConfigId,
            props.configuration,
        );
        const newQuantities = mapValues(arrToObj($event), (id) => Math.max(quantities[id] ?? 1, 1));
        $store.setInnerConfigQuantities(
            props.withinWithinConfigId,
            props.configuration.id,
            newQuantities,
        );
    } else {
        const quantities = $store.getConfigQuantities(props.configuration.id);
        const newQuantities = Object.assign(quantities, $event);
        $store.setConfigQuantities(props.configuration.id, newQuantities);
    }
};

const accordionLabel = computed(() => {
    if (props.itemWithin || props.itemWithinWithin) {
        if (props.adderPricingSize) {
            const selectedPriceObj = props.configuration.price.find(
                (priceObj) => priceObj.size.id === props.adderPricingSize,
            );
            if (
                selectedPriceObj?.price?.formatted == '$0.00' &&
                (props.configuration.min == 0 || props.configuration.min == null)
            ) {
                return props.configuration.label;
            } else {
                return props.configuration.label + ' +' + selectedPriceObj?.price?.formatted;
            }
        } else {
            return props.configuration.label;
        }
    } else {
        const selectedPriceObj = props.configuration.price.find(
            (priceObj) => priceObj.size.id === $store.form.size,
        );
        if (
            selectedPriceObj?.price?.formatted == '$0.00' &&
            (props.configuration.min == 0 || props.configuration.min == null)
        ) {
            return props.configuration.label;
        } else {
            return props.configuration.label + ' +' + selectedPriceObj?.price?.formatted;
        }
    }
});

const editLabel = (items) => {
    items.map((item) => {
        const originalLabel = item.choice.name.split(' +')[0];
        let selectedPriceObj = item?.price?.find(
            (priceObj) => priceObj.size.id === $store.form.size,
        );
        if (props.itemWithin || props.itemWithinWithin) {
            if (props.adderPricingSize) {
                selectedPriceObj = item.price.find(
                    (priceObj) => priceObj.size.id === props.adderPricingSize,
                );
            }
        }
        const adderFlag =
            Number(selectedPriceObj?.price?.formatted.slice(1)) !== 'NaN' &&
            Number(selectedPriceObj?.price?.formatted.slice(1)) > 0;
        item.choice.name = adderFlag
            ? `${originalLabel} +${selectedPriceObj?.price?.formatted}`
            : originalLabel;
    });
    return items;
};
const isUpgrade = computed(() =>
    props.configuration.configuration_type
        ? props.configuration.configuration_type === 'upgrade'
        : props.configuration.type === 'upgrade',
);
</script>

<template>
    <Accordion
        :is-upgrade="isUpgrade"
        :label="accordionLabel"
        :expanded="expanded"
        :handleAccordion="props.handleAccordion"
        :id="configuration.id"
        class="border-x-2"
        :class="[isUpgrade ? 'my-2 border-t-2' : '', !hideUpgrades ? 'border-b-2' : '']"
    >
        <template #label="{ label }">
            <div class="flex items-center space-x-2">
                <span
                    >{{ label }} <span v-if="configuration.required" class="required">*</span></span
                >
            </div>
        </template>
        <template #default>
            <div v-if="configuration.description" class="text-gray-600">
                {{ configuration.description }}
            </div>
            <Select
                @update:model-value="handleSelections"
                @update:quantities="handleQuantities"
                :model-value="selections"
                :options="
                    configuration.combo_item_id ? editLabel(comboChoices) : editLabel(configChoices)
                "
            />
        </template>
    </Accordion>
</template>

<script setup>
import { computed } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const emit = defineEmits(['remove', 'edit', 'updateQuantity']);

const props = defineProps({
    difference: { type: Object, required: true },
});

const colorClass = computed(() =>
    props.difference?.comboItemOption
        ? 'text-palmers-charcoal-grey font-medium'
        : props.difference?.delta > 0 || props.difference.delta == null
          ? 'text-palmers-avocado-green'
          : 'text-palmers-dragon-fruit',
);

const deltaText = computed(() => {
    if (props.difference?.delta > 0) {
        return props.difference?.adder_display ?? 'Add';
    }

    return 'No';
});
</script>

<template>
    <div class="flex items-center space-x-1" :class="[colorClass]">
        <span
            v-if="difference?.comboItemOption && difference.delta > -1"
            class="font-bold text-palmers-charcoal-grey/80"
            >{{ difference?.comboItemOption.name }}:
        </span>
        <span v-if="!difference?.comboItemOption" class="font-bold">{{ deltaText }}</span>
        <span
            v-if="
                !difference.comboItemOption || (difference.comboItemOption && difference.delta > -1)
            "
            >{{ difference?.name }}</span
        >
        <div v-if="difference?.amount > 1" class="flex items-end leading-none">
            <XMarkIcon class="-mb-px h-4 w-4 stroke-[2]" />
            <span class="leading-none">{{ difference.amount }}</span>
        </div>
    </div>
</template>

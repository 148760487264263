<script setup>
import {
    ref,
    inject,
    onBeforeMount,
    onMounted,
    onUnmounted,
    onUpdated,
    computed,
    watch,
} from 'vue';
import { AddToCartButton } from '.';
import { PlusIcon, MinusIcon } from '@heroicons/vue/24/outline';
import { useMenuItemStore, useCartStore } from '@/customer/Composables';
import InputText from '@/customer/Components/InputText.vue';

const props = defineProps({
    allRequiredSelectionsSelected: {
        type: Boolean,
        default: false,
    },
    minimumQuantity: {
        type: Number,
        required: true,
    },
});

const $store = useMenuItemStore();
const cart = useCartStore();

/**
 * DOM Manipulation to keep bar fixed until it reaches bottom border
 */
const mainLayout = ref(null);
const fixedContainer = ref(null);
const mainFooter = ref(null);

const offset = inject('absoluteOffset');

const checkAddToCartPosition = () => {
    const bottomOfScreen = window.scrollY + window.innerHeight;
    const topOfFooter = mainFooter.value.getBoundingClientRect().top + window.scrollY;
    if (bottomOfScreen < topOfFooter) {
        fixedContainer.value.style.position = 'fixed';
        fixedContainer.value.style.left = 0;
    } else {
        fixedContainer.value.style.position = 'absolute';
        fixedContainer.value.style.left = `-${offset.value}px`;
    }
};

onUpdated(() => checkAddToCartPosition());

onMounted(() => {
    mainFooter.value = document.getElementById('mainFooter');
    mainLayout.value = document.getElementById('defaultLayout');
    window.addEventListener('scroll', checkAddToCartPosition);
});

onUnmounted(() => window.removeEventListener('scroll', checkAddToCartPosition));

// initialize values related to whether this is an edit and quantity
const editingItem = ref($store.menuItem.editing);
const quantity = ref(props.minimumQuantity);

// if we are editing we need to populate the proper quantity
const getQuantity = async () => {
    if (editingItem.value) {
        await cart.fetchCart();
        const activeQuantity = cart.indvGrossQuantity(editingItem.value);
        $store.quantity = activeQuantity;
        quantity.value = activeQuantity;
    }
};

/**
 * callback from store event bus
 * in the event someone goes to edit an item
 * then opens the cart and deletes the item
 * we need to revert to the add to cart button
 * and a base quantity of 1
 */
const itemWasRemoved = ($event) => {
    if ($store.menuItem.editing === cart.getItem($event).uuid) {
        editingItem.value = null;
        quantity.value = 1;
        $store.quantity = 1;
    }
};

/**
 * initialize cart bus, watch for quantity updates in cart
 * so that if someone is editing an item and they have the cart open
 * the quantity they select in the cart will update on the item.
 * also watch for an item removal to check if we are currently
 * on that item with the above callback
 */
onBeforeMount(() => {
    getQuantity();
    cart.bus.on('quantityUpdated', getQuantity);
    cart.bus.on('removedItem', ($event) => itemWasRemoved($event));
});

// bus cleanup
onUnmounted(() => {
    cart.bus.off('quantityUpdated', getQuantity);
    cart.bus.off('removedItem', ($event) => itemWasRemoved($event));
});

const addToCartIsLoading = ref(false);
const belowSmallBreakpoint = computed(() => window.innerWidth < 640);

/**
 * mutate reactive quantity state
 * InputText models as a string so Number() ensures we stay numeric
 */
const handleQuantity = (stepper) => {
    let newQuantity = Number(quantity.value);
    if (stepper === 'decrement' && quantity.value > 1) {
        newQuantity = newQuantity - 1;
    } else if (stepper === 'increment') {
        newQuantity = newQuantity + 1;
    }

    quantity.value = newQuantity;
};

watch(
    () => quantity.value,
    (newVal) => {
        if (Number(newVal) >= props.minimumQuantity) {
            $store.quantity = Number(newVal);
        }
    },
);
</script>

<template>
    <div
        v-if="$store.ready"
        ref="fixedContainer"
        style="position: fixed; left: 0; bottom: 0; right: 0; z-index: 10000"
        class="customShadow z-[10000] flex w-screen items-center justify-center gap-x-6 bg-palmers-havarti px-3"
        :class="{ 'py-3.5': !editingItem }"
    >
        <div
            class="relative flex h-[3.53rem] w-52 grid-cols-4 overflow-hidden border-2 border-gray-200 bg-white font-semibold"
            :class="{ hidden: addToCartIsLoading && belowSmallBreakpoint }"
        >
            <button
                class="z-20 h-full w-1/4 select-none border-r-2 p-1.5 sm:p-3"
                :class="[
                    quantity == 1
                        ? 'cursor-not-allowed text-palmers-charcoal-grey/40'
                        : 'text-palmers-charcoal-grey supports-hover:hover:bg-palmers-dragon-fruit/10 supports-hover:hover:text-palmers-dragon-fruit',
                ]"
                @click="handleQuantity('decrement')"
            >
                <MinusIcon />
            </button>
            <div class="z-10 flex h-full w-1/2 items-center justify-center text-2xl">
                <InputText
                    :maskOptions="{ mask: '###' }"
                    name="quantity"
                    v-model="quantity"
                    class="focus:ring-inset-1 rounded-xl border-none text-center text-xl font-semibold text-palmers-charcoal-grey focus:border-0 focus:border-white focus:ring-white"
                />
            </div>
            <button
                class="z-20 h-full w-1/4 select-none border-l-2 p-1.5 text-palmers-charcoal-grey supports-hover:hover:bg-palmers-dragon-fruit/10 supports-hover:hover:text-palmers-dragon-fruit sm:p-3"
                @click="handleQuantity('increment')"
            >
                <PlusIcon />
            </button>
        </div>
        <div class="w-auto">
            <AddToCartButton
                @loading-state="(e) => (addToCartIsLoading = e)"
                :canClick="allRequiredSelectionsSelected"
                :quantity="Number(quantity)"
                :minimum-quantity="minimumQuantity"
                :innerConfigQuantities="$store.innerConfigQuantities"
                :editing="editingItem"
            />
        </div>
    </div>
</template>

<style scoped>
.customShadow {
    box-shadow: 0px -4px 20px #5b5b5b2d;
    clip-path: inset(-40px 0px 0px 0px);
}
</style>

<script setup>
import { computed } from 'vue';
import { Head, Link } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import AddressCard from '../Partials/AddressCard.vue';
import { default as Button } from '@/customer/Components/BaseButton.vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

const props = defineProps({
    deliveryAddresses: {
        type: Object,
    },
    billingAddresses: {
        type: Object,
    },
});

const deliveryAddresses = computed(() => props.deliveryAddresses.data);

const billingAddresses = computed(() => props.billingAddresses.data);

</script>

<template>
    <AccountSettingsLayout :title="lang.title_index" class="pb-6 md:pb-0">
        <Head title="Profile" />
        <div class="flex justify-around">
            <div class="w-full">
                <h2 class="text-lg font-bold mb-2">{{ lang.title_index_delivery }}</h2>
                <template v-for="address in deliveryAddresses">
                    <AddressCard v-bind="{ ...address }" class="mb-4" />
                </template>
        
                <Button :as="Link" :href="route('customer.profile.addresses.create', {'type': 'delivery'})">
                    {{ lang.add_delivery }}
                </Button>
            </div>
            <div class="w-full">
                <h2 class="text-lg font-bold mb-2">{{ lang.title_index_billing }}</h2>
                <template v-for="address in billingAddresses">
                    <AddressCard v-bind="{ ...address }" class="mb-4"/>
                </template>
        
                <Button :as="Link" :href="route('customer.profile.addresses.create', {'type': 'billing'})">
                    {{ lang.add_billing }}
                </Button>
            </div>
        </div>
        

        
    </AccountSettingsLayout>
</template>

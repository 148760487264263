import { defineStore } from 'pinia';
import { mapAndKey, arrToObj } from '@/common/Utils';
import { set, get, keyBy, flatMap, pickBy, map, mapValues, remove } from 'lodash';
import { formatCurrency } from '../Utils';
import { useCartStore } from '@/customer/Composables';
import { usePage } from '@inertiajs/vue3';

export default defineStore('menuItemStore', {
    state: () => ({
        form: {},
        menuItem: null,
        choices: null,
        innerConfigItems: [],
        selectedInnerConfigChoices: [],
        selectedChoices: [],
        price: {
            raw: 0,
            formatted: formatCurrency(0),
        },
        quantity: 1,
        preventBulkCheckout: true,
    }),
    getters: {
        quantities() {
            return get(this.form, 'quantities');
        },
        oldQuantities() {
            return get(this.form, 'oldQuantities');
        },
        innerConfigQuantities() {
            return get(this.form, 'innerConfigQuantities');
        },
        pricing() {
            return mapAndKey(this.menuItem.pricing, (x) => x.size.id);
        },
        sizes() {
            return map(this.menuItem.pricing, 'size');
        },
        name() {
            return get(this.form, 'name');
        },
        instructions() {
            return get(this.form, 'instructions');
        },
        comboItemOptions() {
            return keyBy(this.menuItem.comboItemOptions, 'id');
        },
        requiredComboItemOptions() {
            return this.menuItem.comboItemOptions?.filter(
                (comboItemOption) => comboItemOption.required,
            );
        },
        requiredComboItemOptionsIds() {
            return map(this.requiredComboItemOptions, 'id');
        },
        configurations() {
            return keyBy(this.menuItem.configurations, 'id');
        },
        requiredConfigurations() {
            return this.menuItem.configurations?.filter((config) => config.required) ?? [];
        },
        requiredConfigurationIds() {
            return map(this.requiredConfigurations, 'id');
        },
        addedPriceForMinConfigs() {
            return get(this.form, 'addedPriceForMinConfigs');
        },
        choiceMap() {
            return mapAndKey(
                flatMap(this.quantities, (x, configuration_id) =>
                    Object.keys(x)
                        .map((id) => ({
                            ...this.choices[id],
                            // c: console.log(this.choices[id], x, id),
                            choice: Object.assign(this.choices[id]?.choice ?? {}, { id }),
                            id,
                            configuration_id,
                        }))
                        .filter((choice) => {
                            return this.getQuantity(choice.id) > 0 || choice.default;
                        }),
                ),
                'id',
            );
        },
        configurableChoices() {
            return Object.keys(
                Object.fromEntries(
                    Object.entries(this.choices).filter(
                        ([key, choice]) =>
                            choice.choice.type === 'configurable' ||
                            choice.choice.type === 'preConfigured' ||
                            choice.choice.type === 'schedule',
                    ),
                ),
            );
        },
        totalChoicePrice() {
            let totalChoicePrice = 0;
            let choicePrice = 0;
            let configurationPrice = 0;
            map(this.quantities, (quantities) =>
                Object.entries(quantities).forEach(([id, val]) => {
                    let choice = this?.choices[id];
                    let priceToUse = this.form.size;
                    if (typeof val === 'object') {
                        //scheduled item within combo item
                        if (choice == undefined) {
                            flatMap(this.choices, (choice1) => {
                                let comboItemOption = this.comboItemOptions
                                    ? this.comboItemOptions[choice1?.comboItemOption_id]
                                    : null;
                                let comboItemOptionPriceObj = comboItemOption?.price.find(
                                    (priceObj) => priceObj.size.id === this.form.size,
                                );
                                let comboItemOptionPrice = comboItemOptionPriceObj?.price?.raw || 0;
                                // totalChoicePrice += comboItemOptionPrice;
                            });
                        }
                    } else {
                        const choicePriceObj = choice?.price.find(
                            (priceObj) => priceObj.size.id === priceToUse,
                        );
                        const choicePrice = choicePriceObj?.price?.raw || 0;

                        const configuration = this.configurations
                            ? this.configurations[choice?.configuration_id]
                            : null;
                        const configurationPriceObj = configuration?.price.find(
                            (priceObj) => priceObj.size.id === this.form.size,
                        );
                        const configurationPrice = configurationPriceObj?.price?.raw || 0;
                        const configurationMin = configuration?.min || 0;

                        const comboItemOption = this.comboItemOptions
                            ? this.comboItemOptions[choice?.comboItemOption_id]
                            : null;
                        const comboItemOptionPriceObj = comboItemOption?.price.find(
                            (priceObj) => priceObj.size.id === this.form.size,
                        );
                        const comboItemOptionPrice = comboItemOptionPriceObj?.price?.raw || 0;

                        let quant = quantities[id];
                        if (typeof quant == 'object') {
                            quant = Object.values(quant)[0];
                        }

                        totalChoicePrice += choicePrice * quant + comboItemOptionPrice;

                        if (configurationMin > 0) {
                            if (
                                Object.values(quantities).reduce(
                                    (accumulator, currentValue) => accumulator + currentValue,
                                    0,
                                ) > configurationMin
                            ) {
                                this.addPriceForMinConfigs(
                                    configurationPrice,
                                    quantities,
                                    configurationMin,
                                    id,
                                );
                            } else {
                                if (this.form.addedPriceForMinConfigs == configurationPrice) {
                                    this.form.addedPriceForMinConfigs = 0;
                                }
                            }
                        } else {
                            totalChoicePrice += configurationPrice * quant;
                        }
                    }
                }),
            );
            totalChoicePrice += this.form.addedPriceForMinConfigs ?? 0;

            return totalChoicePrice;
        },
        totalInnerChoicePrice() {
            let totalInnerChoicePrice = 0;
            map(this.quantities, (quantities) =>
                Object.entries(quantities)?.forEach(([id, val]) => {
                    let configurations = this.choices[id]?.choice.configurations ?? undefined;
                    let priceToUse = this.choices[id]?.adder_pricing_size;
                    let scheduledWithinCombo = false;
                    let useThisId = id;
                    if (configurations == undefined) {
                        Object.entries(this.choices)?.forEach(([choiceId, choiceVal]) => {
                            if (choiceVal.choice.item_id == id) {
                                configurations = choiceVal?.choice?.configurations;
                                priceToUse = choiceVal?.adder_pricing_size;
                                scheduledWithinCombo = true;
                                useThisId = choiceId;
                            }
                        });
                    }
                    if (configurations !== undefined && configurations !== null) {
                        const configurationPrice = [];
                        Object.values(configurations).forEach((value) => {
                            const configurationPriceObj = value.price.find(
                                (priceObj) => priceObj.size.id === priceToUse,
                            );
                            configurationPrice[value.id] = configurationPriceObj?.price?.raw || 0;
                        });
                        const costConfigurationPrice = Object.fromEntries(
                            Object.entries(configurationPrice).filter(
                                ([key, value]) => value !== 0,
                            ),
                        );
                        Object.entries(costConfigurationPrice).forEach(([key, value]) => {
                            if (scheduledWithinCombo) {
                                if (
                                    this.innerConfigQuantities?.[useThisId]?.[key] &&
                                    this.innerConfigQuantities[useThisId][key].length !== 0 &&
                                    Object.keys(this.innerConfigQuantities[useThisId][key])
                                        .length !== 0
                                ) {
                                    let quant = 0;
                                    Object.entries(
                                        this.innerConfigQuantities[useThisId][key],
                                    ).forEach(([quantKey, quantVal]) => {
                                        quant += quantVal;
                                    });
                                    totalInnerChoicePrice += value * quant;
                                }
                            } else {
                                if (
                                    this.innerConfigQuantities?.[id]?.[key] &&
                                    this.innerConfigQuantities[id][key].length !== 0 &&
                                    Object.keys(this.innerConfigQuantities[id][key]).length !== 0
                                ) {
                                    totalInnerChoicePrice += value;
                                }
                            }
                        });
                        Object.values(configurations)?.forEach((value) => {
                            value?.choices?.forEach((val) => {
                                if (scheduledWithinCombo) {
                                    if (
                                        typeof this?.innerConfigQuantities[useThisId] ===
                                            'object' &&
                                        this?.innerConfigQuantities[useThisId] !== null
                                    ) {
                                        Object?.values(
                                            this?.innerConfigQuantities[useThisId],
                                        )?.forEach((v) => {
                                            const keys = Object.keys(v);
                                            const realKeys = keys.map((str) => parseInt(str, 10));
                                            if (realKeys.includes(val.id)) {
                                                const innerChoicePriceObj = val?.price?.find(
                                                    (priceObj) => priceObj.size.id === priceToUse,
                                                );
                                                totalInnerChoicePrice +=
                                                    innerChoicePriceObj?.price?.raw || 0;
                                            }
                                        });
                                    }
                                } else {
                                    if (
                                        typeof this?.innerConfigQuantities[id] === 'object' &&
                                        this?.innerConfigQuantities[id] !== null
                                    ) {
                                        Object?.values(this?.innerConfigQuantities[id])?.forEach(
                                            (v) => {
                                                const keys = Object.keys(v);
                                                const realKeys = keys.map((str) =>
                                                    parseInt(str, 10),
                                                );
                                                if (realKeys.includes(val.id)) {
                                                    const innerChoicePriceObj = val?.price?.find(
                                                        (priceObj) =>
                                                            priceObj.size.id === priceToUse,
                                                    );
                                                    totalInnerChoicePrice +=
                                                        innerChoicePriceObj?.price?.raw || 0;
                                                }
                                            },
                                        );
                                    }
                                }
                            });
                        });
                    }
                }),
            );
            return totalInnerChoicePrice;
        },
        ready() {
            return usePage().props.order.started;
        },
        start() {
            return usePage().props.order.startOverHref;
        },
        startOrder() {
            return usePage().props.order.startOrderHrefGoToMenu;
        }
    },
    actions: {
        addPriceForMinConfigs(configurationPrice, quantities, configurationMin, choiceId) {
            let quantityLength = 0;

            Object.entries(quantities).forEach((item) => {
                quantityLength += item[1];
            });

            this.form.addedPriceForMinConfigs =
                configurationPrice * (quantityLength - configurationMin);
        },
        attachForm(form) {
            this.form = form;
        },
        initializePrice(price) {
            this.setPrice(price.raw);
        },
        initializeMenuItem(menuItem, selectedLocationId) {
            this.menuItem = menuItem;
            const matchingPrice = menuItem.pricing.find(
                (item) => item.size.id === menuItem.size,
            )?.price;
            this.initializePrice(matchingPrice);
            menuItem.type == 'combination'
                ? this.initializeQuantities(menuItem.quantities)
                : this.initializeQuantities(
                      menuItem.quantities,
                      selectedLocationId,
                      menuItem.configurations,
                  );
            menuItem.type == 'combination'
                ? this.initializeItems(
                      menuItem.comboItemOptions,
                      menuItem.innerConfigQuantities,
                      selectedLocationId,
                  )
                : this.initializeChoices(menuItem.configurations, selectedLocationId);

            this.setName(menuItem.custom.name);
            this.setInstructions(menuItem.custom.instructions);
        },
        removeUnavailableIngredients(quantities, location, configurations) {
            const removedIngredients = [];
            flatMap(configurations, (config) => {
                config.choices.map((choice) => {
                    if (choice?.activeLocations) {
                        if (!choice?.activeLocations?.includes(location)) {
                            removedIngredients.push(choice.id);
                        }
                    }
                });
            });

            const quantitiesObj = JSON.parse(quantities);
            if (removedIngredients.length) {
                Object.entries(quantitiesObj).forEach(([key, value]) => {
                    if (Object.keys(value).length) {
                        removedIngredients.forEach((removedIngredient) => {
                            const removedKey = String(removedIngredient);
                            if (value.hasOwnProperty(removedKey)) {
                                delete value[removedKey];
                            }
                        });
                        if (Object.keys(value).length === 0) {
                            delete quantitiesObj[key];
                        }
                    }
                });
            }

            return quantitiesObj;
        },
        initializeQuantities(quantities, selectedLocationId = null, configurations = null) {
            if (selectedLocationId == null || configurations == null) {
                this.setQuantities(JSON.parse(quantities));
            } else {
                const trueQuantities = this.removeUnavailableIngredients(
                    quantities,
                    selectedLocationId,
                    configurations,
                );
                this.setQuantities(trueQuantities);
            }

            this.selectedChoices = mapValues(this.quantities, (x) =>
                Object.keys(x).map((y) => parseInt(y)),
            );
        },
        initializeChoices(configurations, currentLocation) {
            this.choices = keyBy(
                flatMap(configurations, (config) =>
                    config.choices
                        .filter((choice) => {
                            if (choice.activeLocations) {
                                return choice.activeLocations.includes(currentLocation);
                            } else {
                                return true;
                            }
                        })
                        .map((choice) =>
                            Object.assign(choice, {
                                choice: Object.assign(choice.choice, { id: choice.id }),
                                configuration_id: config.id,
                                default: get(this.selectedChoices, config.id, []).includes(
                                    choice.id,
                                ),
                            }),
                        ),
                ),
                'id',
            );
        },
        defaultInnerConfigQuantities(location) {
            Object.values(this.choices).map((choice) => {
                if (
                    choice.item.type == 'configurable' ||
                    choice.item.type == 'preConfigured' ||
                    choice.item.type == 'schedule'
                ) {
                    this.innerConfigItems[choice.id] = choice;
                    this.setInnerConfigQuantities(
                        choice.id,
                        JSON.parse(
                            choice.choice.quantities == undefined ? [] : choice.choice.quantities,
                        ),
                    );
                    this.innerConfigQuantities[choice.id] = JSON.parse(choice.choice.quantities);
                    this.selectedInnerConfigChoices = mapValues(
                        this.innerConfigQuantities,
                        (innerArray) => {
                            if (innerArray) {
                                if (Object.keys(innerArray).length < 2) {
                                    return mapValues(innerArray[Object.keys(innerArray)[0]], (x) =>
                                        Object.keys(x).map((y) => parseInt(y)),
                                    );
                                } else {
                                    return mapValues(innerArray, (x) =>
                                        Object.keys(x).map((y) => parseInt(y)),
                                    );
                                }
                            }
                        },
                    );
                }
            });
        },
        initializeItems(comboItemOptions, innerConfigQuantities, location) {
            this.choices = keyBy(
                flatMap(comboItemOptions, (comboItemOption) =>
                    comboItemOption.items.map((item) =>
                        Object.assign(item, {
                            choice: Object.assign(item.item, { id: item.id }),
                            comboItemOption_id: comboItemOption.id,
                            comboItemOption: comboItemOption,
                            default: get(this.selectedChoices, comboItemOption.id, []).includes(
                                item.id,
                            ),
                        }),
                    ),
                ),
                'id',
            );
            if (innerConfigQuantities !== 'null') {
                this.setInnerConfigQuantitiesPlain(JSON.parse(innerConfigQuantities));
            } else {
                this.defaultInnerConfigQuantities(location);
            }
        },
        setRequiredInnerConfigs(required) {
            set(this.form, 'requiredInnerConfigs', required);
        },
        getRequiredInnerConfigs() {
            return get(this.form, 'requiredInnerConfigs');
        },
        setSize(value) {
            this.menuItem.size = value;
            this.price = get(this.pricing, [value, 'price'].join('.'));
            set(this.form, 'size', value);
        },
        setQuantities(quantities) {
            set(this.form, 'oldQuantities', this.quantities ?? null);
            set(this.form, 'quantities', quantities);
        },
        setName(value) {
            set(this.form, 'name', value);
        },
        setInstructions(value) {
            set(this.form, 'instructions', value);
        },
        getConfigQuantities(config_id) {
            return get(this.form, ['quantities', config_id].join('.'));
        },
        getInnerConfigQuantities(choice_id, config, inner = null) {
            if (inner !== null) {
                return get(
                    this.form,
                    ['innerConfigQuantities', choice_id, config.id, inner].join('.'),
                );
            }
            const innerConfigQuantities = get(
                this.form,
                ['innerConfigQuantities', choice_id, config.id].join('.'),
            );
            const choiceIds = [];
            if (Array.isArray(config.choices)) {
                config.choices.map((choice) => {
                    choiceIds.push(choice.id);
                });
            }
            if (innerConfigQuantities) {
                Object.keys(innerConfigQuantities).forEach((key) => {
                    if (!choiceIds.includes(Number(key))) {
                        delete innerConfigQuantities[key];
                    }
                });
            }
            return innerConfigQuantities;
        },
        getInnerConfigQuantitySum(config, choice_id) {
            if (this.getInnerConfigQuantities(choice_id, config) == undefined) {
                return 0;
            }
            return (
                Object.values(this.getInnerConfigQuantities(choice_id, config)).reduce(
                    (c, i) => c + i,
                    0,
                ) ?? 0
            );
        },
        getConfigQuantitySum(config_id) {
            return (
                Object.values(this.getConfigQuantities(config_id)).reduce((c, i) => c + i, 0) ?? 0
            );
        },
        isInnerConfigQuantityValid(config_id, choice_id, comboItemOption) {
            const config = this.comboItemOptions[comboItemOption].items
                .filter((item) => item.id == choice_id)[0]
                .choice.configurations.filter((config) => config.id == config_id)[0];
            const sum = this.getInnerConfigQuantitySum(choice_id, config_id);
            const max = config.max || Number.MAX_SAFE_INTEGER;
            const min = config.min || 0;
            return sum <= max && sum >= min;
        },
        isConfigQuantityValid(config_id) {
            const sum = this.getConfigQuantitySum(config_id);
            const config = this.configurations[config_id];
            const max = config.max || Number.MAX_SAFE_INTEGER;
            const min = config.min || 0;
            // console.log({ sum, max: max, min: min });
            return sum <= max && sum >= min;
        },
        setConfigQuantities(config_id, quantities) {
            set(this.form, ['quantities', config_id].join('.'), quantities);
            this.updateSelectionsFromQuantities(config_id, quantities);
        },
        updateSelectionsFromQuantities(config_id, quantities) {
            let newSelections = Object.keys(pickBy(quantities, (v) => v > 0)).map((x) =>
                parseInt(x),
            );
            if (newSelections.length == 0) {
                newSelections = Object.keys(quantities).map((x) => parseInt(x));
            }
            this.setConfigSelections(config_id, newSelections);
        },
        setConfigSelections(config_id, selections) {
            set(this.selectedChoices, config_id, selections);
        },
        setInnerConfigQuantities(choice_id, config_id, quantities) {
            set(this.form, ['innerConfigQuantities', choice_id, config_id].join('.'), quantities);
            this.updateInnerConfigSelectionsFromQuantities(choice_id, config_id, quantities);
        },
        setInnerConfigQuantitiesPlain(value) {
            set(this.form, 'innerConfigQuantities', value);
            this.setInnerConfigSelectionsPlain(value);
        },
        setInnerConfigSelectionsPlain(value) {
            const object = {};
            mapValues(value, (innerArray, innerKey) => {
                mapValues(innerArray, (quantities, quantityKey) => {
                    mapValues(quantities, (quantity, choice) => {
                        if (!object[innerKey]) {
                            object[innerKey] = {};
                        }
                        if (!object[innerKey][quantityKey]) {
                            object[innerKey][quantityKey] = []; // Initialize array if not exists
                        }
                        object[innerKey][quantityKey].push(parseInt(choice));
                    });
                });
            });
            this.selectedInnerConfigChoices = object;
        },
        updateInnerConfigSelectionsFromQuantities(choice_id, config_id, quantities) {
            const newSelections = Object.keys(pickBy(quantities, (v) => v > 0)).map((x) =>
                parseInt(x),
            );
            this.setInnerConfigSelections(choice_id, config_id, newSelections);
        },
        setInnerConfigSelections(choice_id, config_id, selections) {
            set(this.selectedInnerConfigChoices[choice_id], config_id, selections);
        },
        getChoice(choice_id) {
            return get(this.choices, [choice_id].join('.'));
        },
        getConfigurationId(choice_id) {
            return get(this.choices, [choice_id, 'configuration_id'].join('.'));
        },
        getComboItemOptionId(choice_id) {
            return get(this.choices, [choice_id, 'combo_item_option_id'].join('.'));
        },
        getConfiguration(choice_id) {
            return get(this.configurations, this.getConfigurationId(choice_id));
        },
        getQuantity(choice_id) {
            const config_id = this.getConfigurationId(choice_id);
            if (!config_id) {
                return 0;
            }
            return get(this.quantities, [config_id, choice_id].join('.'), 0);
        },
        setQuantity(choice_id, value) {
            if (!choice_id) {
                throw new Error('expected choice_id');
            }
            const config_id = this.getConfigurationId(choice_id);
            if (!config_id) {
                throw new Error('expected config_id');
            }
            set(this.oldQuantities, this.quantities ?? null);
            set(this.quantities, [config_id, choice_id].join('.'), Math.max(0, value));

            const quantities = this.getConfigQuantities(config_id);
            this.updateSelectionsFromQuantities(config_id, quantities);
        },
        setPrice(value) {
            this.price = {
                raw: value,
                formatted: formatCurrency(value),
            };
        },
        async addToCart(quantity = 1, items = null) {
            const cart = useCartStore();

            if (items !== null) {
                if (Array.isArray(items)) {
                    await cart.addItems(items);
                } else {
                    await cart.addItem(items);
                }
                await cart.fetchCart();
                return;
            }

            const currentItem = this.form.data();
            currentItem.quantity = quantity;
            currentItem.single_size = this.sizes.length <= 1;

            await cart.addItem(currentItem);
            await cart.fetchCart();

            return;
        },
        async updateCart(quantity = null) {
            /**
             * @hack makes second request to persist quantity.
             */
            const cart = useCartStore();
            const formData = this.form.data();
            if (quantity) {
                formData.quantity = quantity;
            }
            formData.single_size = this.sizes.length <= 1;
            await cart.updateItem(this.menuItem.editing, formData);
            await cart.fetchCart();
        },
        testRequiredSelections() {
            return this.requiredConfigurationIds.every(this.isConfigQuantityValid);
        },
        testCanAddToCart() {
            return this.testRequiredSelections();
        },
    },
});

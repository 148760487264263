<script setup>
import { MenuType } from '@/common/Utils/enums.js';
import CheckoutButton from './CheckoutButton.vue';
import {
    useCartStore,
    useCheckoutStore,
    useOrderStore,
    useValidatorStore,
} from '@/customer/Composables';
import { Tooltip } from '@/customer/Components';
import { computed, watch } from 'vue';
import moment from 'moment';

defineProps({
    preventCheckout: {
        type: Boolean,
        default: false,
        required: false,
    },
    fees: {
        type: [Array, Object],
    },
});

const checkout = useCheckoutStore();
const validator = useValidatorStore();
const order = useOrderStore();
const cart = useCartStore();
const readyAtDay = computed(() => moment(order.form.ready_at).day());
const hasInvalidMessages = computed(() => {
    if (cart.items && typeof cart.items === 'object') {
        const itemsArray = Object.values(cart.items);
        const hasInvalidMessage = itemsArray.some((item) => item.unavailable_message !== null);
        return hasInvalidMessage;
    }
    return false;
});

watch(
    () => readyAtDay.value,
    () => {
        cart.fetchCart();
    },
);
</script>

<template>
    <div
        class="h-min w-full rounded-xl border-2 border-palmers-charcoal-grey bg-white p-4 py-3 text-red-500 shadow-lg"
        v-if="checkout.failed"
    >
        <p class="text-lg font-semibold text-palmers-charcoal-grey">
            An Issue Occurred with Checkout
        </p>
        <p>
            {{
                validator.errors.authnet ??
                validator.errors.acceptjs ??
                validator.errors.orderingDisabled ??
                'Some required fields are missing, or the information entered is incorrect. Please review.'
            }}
        </p>
    </div>
    <div
        class="item-center mt-4 flex flex-wrap items-start gap-4 text-palmers-charcoal-grey md:mt-6 md:flex-nowrap md:gap-6"
    >
        <a
            :href="route('customer.menu.index', { type: MenuType.MAIN })"
            class="h-min w-full rounded-xl border-2 border-palmers-charcoal-grey bg-white p-4 text-center text-xl font-bold shadow-lg supports-hover:hover:bg-palmers-avocado-green md:w-1/2"
            id="still_hungry"
        >
            STILL HUNGRY?
        </a>
        <Tooltip
            v-if="hasInvalidMessages"
            message="Please check your cart"
            class="flex w-full items-start space-x-2 md:w-1/2"
        >
            <a
                href="#"
                class="w-full cursor-not-allowed space-x-2 rounded-xl border-2 border-palmers-charcoal-grey bg-white p-4 text-center text-xl font-bold shadow-lg disabled:bg-neutral-200 disabled:bg-opacity-80 disabled:supports-hover:hover:bg-neutral-200 disabled:supports-hover:hover:bg-opacity-80"
            >
                PLACE ORDER
            </a>
        </Tooltip>
        <Tooltip
            v-else-if="
                order.isDelivery && cart.summary.subtotal.raw < parseFloat(fees?.deliveryMin)
            "
            :message="'Deliveries must be at least $' + parseFloat(fees.deliveryMin)"
            class="flex w-full items-start space-x-2 md:w-1/2"
        >
            <a
                href="#"
                class="w-full cursor-not-allowed space-x-2 rounded-xl border-2 border-palmers-charcoal-grey bg-white p-4 text-center text-xl font-bold shadow-lg disabled:bg-neutral-200 disabled:bg-opacity-80 disabled:supports-hover:hover:bg-neutral-200 disabled:supports-hover:hover:bg-opacity-80"
            >
                PLACE ORDER
            </a>
        </Tooltip>
        <Tooltip
            v-else-if="cart.summary.subtotal.raw >= parseFloat(fees?.spendMax)"
            :message="'For orders over $' + parseFloat(fees.spendMax) + ', please call'"
            class="flex w-full items-start space-x-2 md:w-1/2"
        >
            <a
                href="#"
                class="w-full cursor-not-allowed space-x-2 rounded-xl border-2 border-palmers-charcoal-grey bg-white p-4 text-center text-xl font-bold shadow-lg disabled:bg-neutral-200 disabled:bg-opacity-80 disabled:supports-hover:hover:bg-neutral-200 disabled:supports-hover:hover:bg-opacity-80"
            >
                PLACE ORDER
            </a>
        </Tooltip>
        <div v-else class="flex w-full flex-col items-start space-x-2 md:w-1/2">
            <CheckoutButton :prevent-checkout="preventCheckout" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import InputLabel from './InputLabel.vue';
import InputText from './InputText.vue';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    modelValue: {
        type: String,
        required: true,
    },
    class: {
        type: String,
        default: '',
    },
    required: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        required: false,
    },
    errorMessage: {
        type: String,
        default: null,
    },
    confirmErrorMessage: {
        type: String,
        default: null,
    },
    autocomplete: {
        type: String,
        default: 'new-password',
    },
    placeholder: {
        type: String,
        required: false,
    },
    help: {
        type: String,
        required: false,
    },
    type: {
        type: String,
        required: false,
    },
    labelClass: {
        type: String,
        required: false,
    },
});

const emit = defineEmits(['update:modelValue']);

const passwordVisible = ref(false);

const togglePasswordVisibility = () => {
    passwordVisible.value = !passwordVisible.value;
};
</script>
<template>
    <div class="!m-0 border-none">
        <div>
            <slot name="label" v-if="!type?.props?.label">
                <InputLabel
                    v-if="label"
                    :for="name"
                    :value="label"
                    :help="help"
                    :class="labelClass"
                />
            </slot>
            <div class="relative mt-1">
                <input
                    :id="id"
                    :name="name"
                    :type="passwordVisible ? 'text' : 'password'"
                    class="w-full rounded-full border-2 border-palmers-dragon-fruit bg-white px-6 py-3 text-base tracking-widest text-black focus:border-palmers-dragon-fruit focus:ring-palmers-dragon-fruit focus:ring-offset-2"
                    :class="class"
                    :value="modelValue"
                    :required="required"
                    :autocomplete="autocomplete"
                    :placeholder="placeholder"
                    @input="$emit('update:modelValue', $event.target.value)"
                />
                <button
                    type="button"
                    @click="togglePasswordVisibility"
                    :aria-label="passwordVisible ? 'Hide password' : 'Show password'"
                    class="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5"
                >
                    <EyeSlashIcon
                        v-if="passwordVisible"
                        class="h-5 w-5 text-palmers-dragon-fruit"
                    />
                    <EyeIcon v-else class="h-5 w-5 text-palmers-dragon-fruit" />
                </button>
            </div>
            <p v-if="errorMessage" class="mt-2 text-sm text-red-600">{{ errorMessage }}</p>
        </div>
    </div>
</template>

<style scoped>
/* Add custom styles if necessary */
.relative {
    position: relative;
}

button {
    background: none;
    border: none;
    cursor: pointer;
}
</style>

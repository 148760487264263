<script setup>
import { computed, reactive, watch } from 'vue';
import { Head, usePage } from '@inertiajs/vue3';
import { BuildingStorefrontIcon, ClockIcon } from '@heroicons/vue/24/outline';
import AppIcon from '@/customer/Components/AppIcon.vue';
import InputTextarea from '@/customer/Components/InputTextarea.vue';
import { MenuType } from '@/common/Utils/enums.js';
import InputGroup from '@/customer/Components/InputGroup.vue';
import InputText from '@/customer/Components/InputText.vue';
import SelectBox from '@/customer/Components/UI/Input/Select/SelectBox.vue';
import BaseButton from '@/customer/Components/BaseButton.vue';
import CartItem from '@/customer/Components/Cart/CartItem.vue';
import AddOnItems from '@/customer/Components/Cart/AddOnItems.vue';
import { useCart, useCartStore, useApp } from '@/customer/Composables';

defineProps({
    app: { type: Object },
});

const tip_options = [15, 20, 25];

const {fees} = useApp();

const months = [
    { label: '01', value: 0 },
    { label: '02', value: 1 },
    { label: '03', value: 2 },
    { label: '04', value: 3 },
    { label: '05', value: 4 },
    { label: '06', value: 5 },
    { label: '07', value: 6 },
    { label: '08', value: 7 },
    { label: '09', value: 8 },
    { label: '10', value: 9 },
    { label: '11', value: 10 },
    { label: '12', value: 11 },
];

const years = computed(() => {
    return Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i).map((year) => ({
        label: year,
        value: year,
    }));
});

const order = reactive({
    location: {
        name: 'Ingersoll',
        nameWithStreet: 'Ingersoll: 3675 Waldo Mills',
    },
    fulfillment_type: {
        value: 'carryout',
        label: 'Carry-Out',
    },
    ready_type: {
        value: 'scheduled',
        label: 'Scheduled',
    },
    ready_at: null,
    special_instructions: '',
    discount_code: null,
    subtotal: '26.90',
    payment_information: {
        first_name: null,
        last_name: null,
        card_number: null,
        expiration_month: null,
        expiration_year: null,
        zipcode: null,
        card_verification_number: null,
    },
    tip_percent: null,
    tip_custom: null,
    items: [
        {
            id: 1,
            name: 'Marshall Field Box Lunch #1',
            image: null,
            quantity: 1,
            cost: '13.45',
            ingredients_removed: ['1000 Island', 'Saurkraut'],
            ingredients_added: ['Tomato'],
        },
        {
            id: 2,
            name: 'Marshall Field Box Lunch #1',
            image: null,
            quantity: 1,
            cost: '13.45',
            ingredients_removed: ['Mayonnaise', 'Pickles'],
            ingredients_added: ['Ham'],
        },
    ],
});

const cart = useCart();

const timeValue = computed(() => {
    if (order.ready_type.value == 'asap') {
        return order.ready_type.label;
    } else {
        return 'Today @ 1:30 p.m.';
    }
});

watch(
    () => order.tip_custom,
    (value, oldValue) => {
        if (value == null || value == '') return;
        order.tip_percent = null;
        cart.applyTip();
    },
);

const setTipPercent = (tip) => {
    order.tip_custom = null;
    order.tip_percent = tip;
    cart.applyTip();
};
</script>

<template>
    <Head :title="`Checkout`" />

    <div class="-mx-6 -my-16 md:grid md:grid-cols-2">
        <div class="flex justify-center bg-palmers-dragon-fruit px-6 py-16 text-white">
            <div class="md:max-w-[30rem]">
                <h1 class="text-xl font-bold uppercase">Order Summary:</h1>
                <div class="items-top mt-4 flex space-x-4">
                    <BuildingStorefrontIcon class="h-9 w-9" />
                    <div>
                        <div class="font-bold">Location</div>
                        <p>
                            {{ order.location.nameWithStreet }}
                        </p>
                    </div>
                </div>
                <div class="items-top mt-4 flex space-x-4">
                    <AppIcon icon="BagIcon" class="h-9 w-9" />
                    <div>
                        <div class="font-bold">Delivery or Carry-Out</div>
                        <p>
                            {{ order.fulfillment_type.label }}
                        </p>
                    </div>
                </div>
                <div class="items-top mt-4 flex space-x-4">
                    <ClockIcon class="h-9 w-9" />
                    <div>
                        <div class="font-bold">Time</div>
                        <p>
                            {{ timeValue }}
                        </p>
                    </div>
                </div>
                <div class="mt-10">
                    <div class="font-bold">Special Instructions</div>
                    <InputTextarea
                        class="mt-2 min-h-[15rem] w-full text-black"
                        v-model="order.special_instructions"
                    />
                </div>
                <div class="mt-10">
                    <div class="font-bold">Discount Code:</div>
                    <div class="mt-2 flex justify-between">
                        <InputText
                            class="flex-1 text-palmers-charcoal-grey"
                            v-model="order.discount_code"
                        />
                        <BaseButton class="ml-2 shadow-none" @click="cart.applyDiscountCode()"
                            >Apply</BaseButton
                        >
                    </div>
                </div>
                <div class="mt-4">
                    <div class="text-xl font-bold">SUBTOTAL:</div>
                    <p class="mt-2 text-5xl font-bold">${{ order.subtotal }}</p>
                </div>
                <div class="mt-4">
                    <div class="text-xl font-bold">ENTER CREDIT CARD DETAILS</div>
                    <div class="mt-2">
                        <InputGroup
                            class="w-full text-black"
                            label-class="text-white"
                            label="First Name"
                            v-model="order.payment_information.first_name"
                        />
                    </div>
                    <div class="mt-2">
                        <InputGroup
                            class="w-full text-black"
                            label-class="text-white"
                            label="Last Name"
                            v-model="order.payment_information.last_name"
                        />
                    </div>
                    <div class="mt-2">
                        <InputGroup
                            class="w-full text-black"
                            label-class="text-white"
                            label="Credit Card Number"
                            v-model="order.payment_information.card_number"
                        />
                    </div>
                    <div class="mt-2">
                        <p>Expiration Date</p>
                        <div class="grid grid-cols-2 gap-x-2">
                            <SelectBox
                                class="text-black"
                                label="Month"
                                label-class="text-white"
                                :options="months"
                                v-model="order.payment_information.expiration_month"
                            />

                            <SelectBox
                                class="text-black"
                                label="Year"
                                label-class="text-white"
                                labelKey="value"
                                :options="years"
                                v-model="order.payment_information.expiration_year"
                            />
                        </div>
                    </div>
                    <div class="mt-2">
                        <InputGroup
                            class="w-full text-black"
                            label-class="text-white"
                            label="Zip Code"
                            v-model="order.payment_information.zipcode"
                        />
                    </div>
                    <div class="mt-2">
                        <InputGroup
                            class="w-full text-black"
                            label-class="text-white"
                            label="Card Verification Number"
                            v-model="order.payment_information.card_verification_number"
                        />
                    </div>
                </div>
                <div class="mt-4">
                    <div class="font-bold">TIP OPTIONAL</div>
                    <div class="flex items-center text-palmers-charcoal-grey">
                        <div class="flex space-x-1">
                            <button
                                class="text-md rounded-full border-2 border-black px-4 py-2 font-bold supports-hover:hover:bg-palmers-avocado-green"
                                :class="
                                    tip == order.tip_percent
                                        ? 'bg-palmers-avocado-green'
                                        : 'bg-white'
                                "
                                @click="setTipPercent(tip)"
                                v-for="tip in tip_options"
                            >
                                {{ tip }}%
                            </button>
                        </div>
                        <div class="ml-2 flex items-center">
                            <span class="mr-1 text-3xl text-white">$</span>
                            <InputText
                                class="w-full"
                                placeholder="0.00"
                                v-model="order.tip_custom"
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <p class="text-right text-sm">
                        If you have Celiac disease and/or are highly sensitive, please inform the
                        person taking your order. While we will make every effort to accommodate
                        your needs, we cannot guarantee that your order will not come into contact
                        with gluten at some point in the process.
                    </p>
                </div>

                <div
                    class="item-center mt-4 flex flex-wrap justify-between text-palmers-charcoal-grey"
                >
                    <a
                        :href="route('customer.menu.index', { type: MenuType.MAIN })"
                        class="my-4 rounded-full border-2 border-palmers-charcoal-grey bg-white px-4 py-2 text-xl font-bold supports-hover:hover:bg-palmers-avocado-green"
                    >
                        STILL HUNGRY?
                    </a>

                    <Tooltip
                        v-if="order.isDelivery && cart.summary.subtotal.raw < parseFloat(fees.deliveryMin)"
                        :message="'Deliveries must be at least $' + parseFloat(fees.deliveryMin)"
                        class="mb-4 flex w-full items-center justify-center sm:mb-0 sm:w-auto"
                    >
                        <a
                            href="#"
                            class="w-full cursor-not-allowed rounded-full border-2 border-black bg-white px-4 py-3 text-center text-xl font-bold sm:w-auto sm:py-2"
                        >
                            CHECKOUT
                        </a>
                    </Tooltip>
                    <Tooltip
                        v-if="order.isDelivery && cart.summary.subtotal.raw < parseFloat(fees.spendMax)"
                        :message="'For orders over $' + parseFloat(fees.spendMax) + ', please call'"
                        class="mb-4 flex w-full items-center justify-center sm:mb-0 sm:w-auto"
                    >
                        <a
                            href="#"
                            class="w-full cursor-not-allowed rounded-full border-2 border-black bg-white px-4 py-3 text-center text-xl font-bold sm:w-auto sm:py-2"
                        >
                            CHECKOUT
                        </a>
                    </Tooltip>
                    <a
                        v-else
                        :href="route('customer.checkout.index')"
                        class="mb-4 w-full rounded-full border-2 border-black bg-white px-4 py-3 text-center text-xl font-bold supports-hover:hover:bg-palmers-avocado-green sm:mb-0 sm:w-auto sm:py-2"
                    >
                        CHECKOUT
                    </a>
                </div>
            </div>
        </div>
        <div>
            <div class="my-8">
                <ul role="list" class="-my-8 divide-y-2" v-if="order.items?.length > 0">
                    <template v-for="item in Object.values(order.items)" :key="item.id">
                        <CartItem
                            class="px-10 py-8 lg:px-20"
                            as="li"
                            :item="item"
                            @remove="cart.removeItem(item)"
                            @edit="cart.editItem(item)"
                            @updateQuantity="cart.updateItemQuantity(item)"
                        />
                    </template>
                </ul>
                <div class="px-10 py-8 text-palmers-charcoal-grey lg:px-20" v-else>
                    <h1 class="text-xl">Your cart is empty</h1>
                </div>
            </div>
            <template v-for="(menu, i) of cart.menus" :key="i">
                <AddOnItems :items="menu.menuItems" :label="menu.name" />
            </template>
        </div>
    </div>
</template>

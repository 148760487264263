import './bootstrap';

// assets
import.meta.glob(['../img/**', '../fonts/**']);

import { computed, createApp, h } from 'vue';
import { createInertiaApp, usePage } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import MainLayout from '@/customer/Layouts/MainLayout.vue';
import DD from '@/common/Components/DD.vue';
import { createPinia } from 'pinia';
import { ReportExceptionsPlugin } from '@/common/Plugins';
import PrimeVue from 'primevue/config';
import Lara from '../css/primevue/customer/presets/lara';
import 'primeicons/primeicons.css';

import * as Sentry from '@sentry/vue';
import { createSentryPiniaPlugin } from '@sentry/vue';

import { VueReCaptcha } from 'vue-recaptcha-v3';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const APP_CENTRAL_DOMAIN = import.meta.env.VITE_APP_CENTRAL_DOMAIN;
const PALMERS_DOMAIN = import.meta.env.VITE_PALMERS_DOMAIN;
const SENTRY_ENABLED = import.meta.env.VITE_SENTRY_ENABLED || false;
const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV || 'local';
const GOOGLE_RECAPTCHA_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY;

const app = createInertiaApp({
    title: (title) => `${title} - ${usePage().props.app.name}`,
    resolve: (name) =>
        resolvePageComponent(
            `./customer/Pages/${name}.vue`,
            import.meta.glob('./customer/Pages/**/*.vue', { eager: true }),
        ).then((module) => {
            // set default layout
            if(name == 'Menu/Print') {
                module.default.layout = null;
            } else {
                module.default.layout = module.default.layout || MainLayout;
            }

            return module;
        }),
    setup({ el, App, props, plugin }) {
        const pinia = createPinia();
        if (SENTRY_ENABLED) {
            Sentry.init({
                app: createApp({ render: () => h(App, props) }),
                dsn: 'https://420df3965e976cdec829c2ec117f488d@o4508064995868672.ingest.us.sentry.io/4508185407782912',
                integrations: [Sentry.replayIntegration()],
                tracesSampleRate: 0.1,
                tracePropagationTargets: [
                    `https://${PALMERS_DOMAIN}`,
                    new RegExp(`^https://.*\\.${APP_CENTRAL_DOMAIN}.*`),
                ],
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
                tracingOptions: {
                    trackComponents: true,
                    timeout: 1000,
                    hooks: ['mount', 'update', 'unmount'],
                },
                normalizeDepth: 10,
                environment: SENTRY_ENV,
            });
            pinia.use(createSentryPiniaPlugin());
        }
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ReportExceptionsPlugin)
            .use(ZiggyVue, Ziggy)
            .use(pinia)
            .use(PrimeVue, {
                unstyled: true,
                pt: Lara,
            })
            .use(VueReCaptcha, {
                siteKey: GOOGLE_RECAPTCHA_KEY,
            })
            .component('DD', DD)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});

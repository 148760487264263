<script setup>
import Form from '@/customer/Pages/Profile/Partials/Form.vue';
import InputGroup from '@/customer/Components/InputGroup.vue';
import SelectBox from '@/customer/Components/UI/Input/Select/SelectBox.vue';
import Checkbox from '@/customer/Components/UI/Input/Checkboxes/CheckboxInput.vue';
import PhoneInput from '@/customer/Components/UI/Input/MaskedInputs/PhoneInput.vue';
import TextareaInput from '@/customer/Components/UI/Input/TextareaInput.vue';
import { useLang } from '@/customer/Composables';
import InputSelectState from '@/customer/Components/InputSelectState.vue';
import { states } from '@/common/Utils/states';
const { lang } = useLang();
import { string, boolean } from 'yup';
import { checkStateZip } from '@/customer/Composables/useCheckStateZip';
import { computed, onMounted, ref, watch } from 'vue';
import InputError from '@/customer/Components/InputError.vue';
import moment from 'moment';
import * as Cards from '@/common/Utils/cards';
import {
    CreditCardNumberInput,
    CreditCardExpirationInput,
    CreditCardCodeInput,
} from '@/customer/Components';
import { PlusCircleIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { usePage } from '@inertiajs/vue3';

defineEmits(['submit']);

const props = defineProps({
    form: { type: Object },
    billingAddresses: { type: Object },
});
const form = ref(props.form);
const errors = ref([]);
const now = moment();
const month = Number(now.format('M'));
const year = Number(now.format('YY'));
const expirationRegEx = new RegExp(
    `^(0[${month}-9]|1[0-2])\/${year}$|^(0[1-9]|1[0-2])\/(2[5-9]|[3-9]\\d)$`,
);

const cityStateZipIsValid = ref(true);
watch(
    () => [form.value.state, form.value.zip],
    () => {
        const inputState = form.value.state;
        const inputZip = form.value.zip;
        if (
            typeof inputState === 'string' &&
            inputState.length > 0 &&
            typeof inputZip === 'string' &&
            inputZip.length > 0
        ) {
            cityStateZipIsValid.value = checkStateZip(inputState, inputZip);
            if (!cityStateZipIsValid.value) {
                errors.value.push('Zip code does not match selected state');
            } else {
                errors.value.length = 0;
            }
        }
    },
);

const billingAddressOptions = computed(() => {
  return props.billingAddresses.map(address => ({
    value: address.id,
    label: `${address.street}, ${address.city}, ${address.state}, ${address.zip}`
  }));
});

const createNewBillingAddress = computed({
    get() {
        return form.value.create_new_address;
    },
    set(value) {
        form.value.create_new_address = value;
        form.value.address = value ? { type: 'billing' } : null;
    },
});

const toggleCreateNewBillingAddress = () => {
    createNewBillingAddress.value = !createNewBillingAddress.value;
};

onMounted(() => {
    if(!props.billingAddresses.length) {
        form.value.create_new_address = true;
        form.value.address = { type: 'billing' }
    }
})
 
</script>

<template>
    <Form v-bind="{ form }" :disabled="!cityStateZipIsValid" @submit="$emit('submit')">
        
        <div class="grid grid-cols-2">
            <InputGroup
                id="first_name"
                v-model="form.first_name"
                label="First Name"
                name="first_name"
                :error-message="form.errors.first_name"
                type="text"
                class="mt-1 block mr-1"
                autofocus
                autocomplete="given-name"
                required
            />

            <InputGroup
                id="last_name"
                v-model="form.last_name"
                label="Last Name"
                name="last_name"
                :error-message="form.errors.last_name"
                type="text"
                class="mt-1 block ml-1"
                autocomplete="family-name"
                required
            />
        </div>
        <div class="flex justify-between">
            <label class="block font-semibold tracking-wide text-gray-700 mb-0 mt-1">Billing Address</label>
            <div v-if="billingAddresses.length" @click="toggleCreateNewBillingAddress" class="text-sm underline cursor-pointer mb-0 mt-1">{{ createNewBillingAddress ? 'Select Existing Billing Address' : 'Create New Billing Address' }}</div>
        </div>
        <InputGroup v-if="!createNewBillingAddress"
            :type="SelectBox"
            :help="null"
            description=""
            placeholder=""
            :options="billingAddressOptions || []"
            :error-message="form.errors.address"
            label-key="label"
            value-key="value"
            v-model="form.address"
            class="w-full"
            required
        />

        <div v-if="createNewBillingAddress" class="pb-8">        
            <InputGroup
                id="street"
                v-model="form.address.street"
                label="Street Address"
                name="street"
                :error-message="form.errors.street"
                type="text"
                class="mt-1 block w-full"
                autocomplete="street-address"
                required
            />

            <InputGroup
                id="street2"
                v-model="form.address.street2"
                label="Unit/Apt/Suite"
                name="street2"
                :error-message="form.errors.street2"
                type="text"
                class="mt-1 block w-full"
                autocomplete="street-address-2"
            />
            <div class="grid grid-cols-2">
                <InputGroup
                    id="city"
                    v-model="form.address.city"
                    label="City"
                    name="city"
                    :error-message="form.errors.city"
                    type="text"
                    class="mt-1 mr-2 block w-full"
                    autocomplete="address-level2"
                    required
                />

                <InputGroup
                    id="state"
                    v-model="form.address.state"
                    label="State"
                    name="state"
                    :states="states"
                    :error-message="form.errors.state"
                    :validator-attributes="['form.state']"
                    :validator="
                        string()
                            .required('cc-state')
                            .test('state-zip', 'state-zip', () => {
                                return cityStateZipIsValid;
                            })
                    "
                    :type="InputSelectState"
                    class="mt-1 block w-full ml-2"
                    autocomplete="address-level1"
                    required
                />
            </div>

            <InputGroup
                id="zip"
                v-model="form.address.zip"
                label="Zip Code"
                name="zip"
                :error-message="form.errors.zip"
                :validator-attributes="['form.zip']"
                :validator="
                    string()
                        .required('cc-zip')
                        .matches(/^\d{5}(?:-\d{4})?$/, 'cc-zip')
                        .test('state-zip', 'state-zip', () => {
                            return cityStateZipIsValid;
                        })
                "
                type="text"
                class="mt-1 block w-full"
                autocomplete="postal-code"
                required
            />
        </div>

        <InputGroup
            class="w-full text-black"
            label-class="text-palmers-charcoal-grey"
            label="Credit Card Number"
            name="cc-number"
            error-class="text-[#404041]"
            :type="CreditCardNumberInput"
            :validator="string().required('Enter A Valid Card Number')"
            :validator-attributes="['E_WC_05']"
            v-model="form.card_number"
            :error-message="form.errors.card_number"
            required
        />

        <InputGroup
            :type="CreditCardExpirationInput"
            label="Expiration Date"
            label-class="text-palmers-charcoal-grey"
            name="cc-exp"
            error-class="text-[#404041]"
            :error-message="form.errors.month"
            v-model:month="form.month"
            v-model:year="form.year"
            :validator="string().required().matches(expirationRegEx, 'Enter Valid Expiration')"
            :validator-attributes="['E_WC_06', 'E_WC_07', 'E_WC_08']"
            required
        />

        <InputGroup
            :type="CreditCardCodeInput"
            class="w-full text-black"
            label-class="text-palmers-charcoal-grey"
            label="CVC"
            name="cc-code"
            error-class="text-[#404041]"
            v-model="form.card_code"
            :vendor="form.card_type?.toUpperCase()"
            :validator-attributes="['E_WC_15']"
            :validator="string().required()"
            :error-message="form.errors.card_code"
            required
        />
        <InputError v-if="errors.length" :message="errors[0]" class="m-2 tracking-normal" />
        <InputError v-if="usePage().props?.errors?.authnet?.length" message="There was a problem saving your card. Please ensure all card information is correct" class="m-2 tracking-normal" />
        <InputError v-if="usePage().props?.errors" :message="usePage().props?.errors['0']" class="m-2 tracking-normal" />
    </Form>
</template>

<script setup>
import { Head, useForm, usePage } from '@inertiajs/vue3';
import AccountSettingsLayout from '@/customer/Layouts/AccountSettingsLayout.vue';
import PaymentMethodForm from '@/customer/Pages/Profile/Partials/PaymentMethodForm.vue';
import { computed } from 'vue';
import { useLang } from '@/customer/Composables';

const { lang } = useLang();

const props = defineProps({
    paymentMethod: { type: Object },
    billingAddresses: { type: Object },
});

const paymentMethod = computed(() => props.paymentMethod);

const form = useForm(route().current(), {
    first_name: paymentMethod.value.first_name,
    last_name: paymentMethod.value.last_name,
    card_number: paymentMethod.value.authnet_pm_last_four,
    card_type: paymentMethod.value.authnet_pm_type,
    card_code: null,
    expiration: paymentMethod.value.authnet_pm_expiration,
    month: paymentMethod.value.month,
    year: paymentMethod.value.year,
    address: paymentMethod.value.address.id ?? { type: 'billing' },
    create_new_address: false
});

const submit = () => {
    const url = route('customer.profile.payment_methods.update', {
        payment_method: paymentMethod.value
    });

    form.patch(url, {
        preserveScroll: true,
        preserveState: true,
    });
};
</script>

<template>
    <AccountSettingsLayout :title="lang.title_edit" class="pb-6 md:pb-0">
        <Head title="Profile" />

        <PaymentMethodForm v-bind="{ form }" :billing-addresses="billingAddresses.data" @submit="submit" />
    </AccountSettingsLayout>
</template>

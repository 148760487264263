<script setup>
import InputLabel from '@/customer/Components/InputLabel.vue';
import InputText from '@/customer/Components/InputText.vue';
import InputError from '@/customer/Components/InputError.vue';
import { getCurrentInstance, computed, watch, ref, unref, onMounted } from 'vue';
import { useValidatorStore } from '@/customer/Composables';
import { onUnmounted } from 'vue';

const validator = useValidatorStore();

defineEmits(['focus', 'blur', 'input', 'change', 'keyup', 'keydown', 'maska']);

const props = defineProps({
    type: {
        type: [Object, String],
        default: 'text',
        required: false,
    },
    label: {
        type: String,
        default: null,
    },
    help: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    errorMessage: {
        type: String,
        default: null,
    },
    labelClass: {
        type: String,
        default: null,
    },
    inputClass: {
        type: String,
        default: null,
    },
    errorClass: {
        type: String,
        default: null,
    },
    validate: {
        type: Boolean,
        default: false,
    },
    validator: {
        type: Object,
        default: null,
    },
    validatorAttribute: {
        type: String,
        default: null,
    },
    validatorAttributes: {
        type: Array,
        default: () => [],
    },
    formattedTextColor: {
        type: String,
        default: 'text-black',
        required: false,
    },
    options: {
        type: Array,
        default: () => [],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    autocomplete: {
        type: String,
        required: false,
    },
    hideErrors: {
        type: Boolean,
        default: false,
    },
});

const validatorAttribute = computed(
    () => props.validatorAttribute ?? props.name ?? getCurrentInstance().uid.toString(),
);

const errorMessage = computed(
    () =>
        props.errorMessage ??
        validator.getError([validatorAttribute.value, ...props.validatorAttributes]),
);

const inputElement = ref(null);

const detectedModelValue = computed(() => unref(inputElement.value.modelValue));

const validate = (value, test = true) => {
    if (props.validator) {
        validator.validate({
            attribute: validatorAttribute.value,
            value: value ?? detectedModelValue.value,
            validator: props.validator,
            attributes: props.validatorAttributes,
            test: test && props.validate,
        });
    }
};

const [inputValue, modifiers] = defineModel({
    set(value) {
        validate(value, props.validate || modifiers.validate);
        return value;
    },
});

watch(
    () => props.validate,
    (newValue) => {
        validate(inputValue.value, newValue);
    },
);

onMounted(() => {
    validate(inputValue.value, false);
});

onUnmounted(() => {
    validator.remove(validatorAttribute.value);
});
</script>
<template>
    <div :data-input-group="validatorAttribute">
        <slot name="label" v-if="!type?.props?.label">
            <InputLabel v-if="label" :for="name" :value="label" :help="help" :class="labelClass">
                <template #ending><slot name="label.ending"/></template>
            </InputLabel>
        </slot>

        <template v-if="typeof type === 'string'">
            <InputText
                ref="inputElement"
                v-bind="{ ...$attrs }"
                :type="type"
                v-model="inputValue"
                :class="[inputClass]"
                :disabled="disabled"
                @focus="$emit('focus', $event)"
                @blur="$emit('blur', $event)"
                @input="$emit('input', $event)"
                @change="$emit('change', $event)"
                @keydown.stop="$emit('keydown', $event)"
                @keyup="$emit('keyup', $event)"
                @maska="$emit('maska', $event)"
                :autocomplete="autocomplete"
            />
        </template>

        <template v-else>
            <component
                ref="inputElement"
                :is="type"
                v-bind="{ ...$attrs, label, errorMessage, name }"
                :options="options"
                :disabled="disabled"
                v-model="inputValue"
                :class="[inputClass]"
                @focus="$emit('focus', $event)"
                @blur="$emit('blur', $event)"
                @input="$emit('input', $event)"
                @change="$emit('change', $event)"
                @keydown.stop="$emit('keydown', $event)"
                @keyup="$emit('keyup', $event)"
                @maska="$emit('maska', $event)"
                :autocomplete="autocomplete"
            />
        </template>

        <slot name="errors" v-if="!type?.props?.errorMessage">
            <InputError
                v-if="!hideErrors"
                :message="errorMessage"
                class="m-2 tracking-normal"
                :messageClass="errorClass"
            />
        </slot>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import CartItemDifference from './CartItemDifference.vue';

const emit = defineEmits(['remove', 'edit', 'updateQuantity']);

const props = defineProps({
    differences: { type: Array, required: true },
    innerConfigDifferences: { type: Array, required: false },
});

const additions = computed(() => props.differences?.filter((x) => x.delta > 0));
const removals = computed(() => props.differences?.filter((x) => x.delta <= 0));
const differences = computed(() => [].concat(removals.value).concat(additions.value));
const innerAdditions = computed(() => props.innerConfigDifferences?.filter((x) => x.delta > 0));
const innerRemovals = computed(() => props.innerConfigDifferences?.filter((x) => x.delta <= 0));
const innerDifferences = computed(() =>
    [].concat(innerRemovals.value).concat(innerAdditions.value),
);

const showingMore = ref(false);
const hideAfter = ref(3);

const sortedDifferences = computed(() => {
    if (differences?.value?.length > 0 && differences.value[0]?.comboItemOption !== null) {
        return differences.value.sort((a, b) => {
            if (a.comboItemOption && b.comboItemOption) {
                return a.comboItemOption.sort_order - b.comboItemOption.sort_order;
            }
            return 0;
        });
    } else {
        return differences.value;
    }
});

const primaryOptionId = computed(
    () => sortedDifferences.value.find((diff) => diff?.comboItemOption?.primary_option)?.item_id,
);

const flatDifferences = computed(() =>
    sortedDifferences.value.flatMap((diff) => {
        if (diff.item_id === primaryOptionId.value) {
            return [...innerDifferences.value.filter((t) => t.item_id === diff.item_id)];
        }

        return [
            diff,
            ...innerDifferences.value
                .filter((t) => t.item_id === diff.item_id)
                .map((inner) => ({ ...inner, innerDifference: true })),
        ];
    }),
);

const shouldShowMore = computed(() => flatDifferences.value.length > hideAfter.value);
</script>

<template>
    <template v-for="(difference, index) in flatDifferences">
        <template v-if="index < hideAfter || showingMore">
            <CartItemDifference
                v-bind="{ difference }"
                v-bind:key="difference?.item_id"
                :class="{ 'ml-5': difference?.innerDifference ?? false }"
            />
        </template>
    </template>
    <template v-if="!showingMore && shouldShowMore">
        <button class="font-semibold" @click="showingMore = true">Show more</button>
    </template>
    <template v-if="showingMore">
        <button class="font-semibold" @click="showingMore = false">Show less</button>
    </template>
</template>

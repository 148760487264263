<script setup>
import { InputGroup, CheckboxGroupInputSingle } from '@/customer/Components';
import { CreditCardForm, ContactInfoForm, PaymentProfileOption } from '.';
import { usePage } from '@inertiajs/vue3';
import { computed, ref, watch } from 'vue';
import { useCheckoutStore, useAuthNetStore } from '@/customer/Composables';
import { CreditCardCodeInput } from '@/customer/Components';
import { CardVendor } from '@/common/Utils/cards';
import { string } from 'yup';

const authnet = useAuthNetStore();
const paymentProfiles = computed(() => usePage().props.paymentProfiles?.data);
const selections = ref([]);

console.log({ paymentProfiles });

const handleSelections = ($event) => {
    selections.value = $event;
    authnet.responseData.paymentProfileId = $event[0];
    authnet.responseData.cardCode = null;
};

const cardVendor = ref(null);
const cvvLength = computed(() => (cardVendor.value === CardVendor.AMEX ? 4 : 3));

watch(
    () => authnet.responseData.paymentProfileId,
    (newProfileId) => {
        const selectedProfile = paymentProfiles.value?.find(
            (profile) => profile.customerPaymentProfileId === newProfileId
        );

        cardVendor.value = selectedProfile?.payment?.creditCard?.cardType;
        if(cardVendor.value == 'AmericanExpress') {
            cardVendor.value = CardVendor.AMEX;
        } else {
            cardVendor.value = CardVendor.GENERIC;
        }
    },
);
</script>
<template>
    <div class="space-y-2">
        <div class="text-xl font-bold text-palmers-charcoal-grey">Saved Payment Methods</div>
        <InputGroup
            :label="null"
            :as="PaymentProfileOption"
            :type="CheckboxGroupInputSingle"
            :options="paymentProfiles"
            value-key="customerPaymentProfileId"
            :model-value="selections"
            @update:model-value="handleSelections"
            wrapperClass="grid grid-cols-2 sm:grid-cols-3 gap-2"
        />

        <div v-if="authnet.responseData.paymentProfileId">
            <div class="flex justify-center mt-5">
                <InputGroup
                    :type="CreditCardCodeInput"
                    class="text-black pt-1"
                    label-class="text-palmers-charcoal-grey"
                    label="CVC"
                    name="cc-code"
                    error-class="text-[#404041]"
                    v-model="authnet.responseData.cardCode"
                    :vendor="cardVendor"
                    :validator-attributes="['E_WC_15']"
                    :validator="string().required().length(cvvLength)"
                />
            </div>
            
            <span class="text-black text-xs flex justify-center mt-2">Please enter the CVC code of the selected payment method</span>
        </div>
    </div>
</template>
